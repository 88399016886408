/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  Upload: any;
};

export type AddBusinessSuggestion = {
  business_name: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  type_business?: InputMaybe<TypeBusiness>;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type Author = {
  __typename?: 'Author';
  avatar?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type AuthorOrderInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type AuthorRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<AuthorsWhereUniqueInput>>;
  create?: InputMaybe<Array<InputMaybe<CreateAuthorInput>>>;
};

export type AuthorRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<AuthorsWhereUniqueInput>>;
  create?: InputMaybe<Array<InputMaybe<CreateAuthorInput>>>;
  disconnect?: InputMaybe<Array<AuthorsWhereUniqueInput>>;
  set?: InputMaybe<Array<AuthorsWhereUniqueInput>>;
};

export type AuthorRelateToOneForCreateInput = {
  connect: AuthorsWhereUniqueInput;
};

export type AuthorRelateToOneForUpdateInput = {
  connect: AuthorsWhereUniqueInput;
};

export type AuthorsResponse = {
  __typename?: 'AuthorsResponse';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<Author>>>;
};

export type AuthorsWhereInput = {
  description?: InputMaybe<NestedStringFilter>;
  id?: InputMaybe<IdFilter>;
  name?: InputMaybe<NestedStringFilter>;
  posts_on_authors?: InputMaybe<PostOnAuthorsRelationFilterInput>;
};

export type AuthorsWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type CancelSubscriptionInput = {
  subscription_id?: InputMaybe<Scalars['Int']>;
};

export type CancelSubscriptionResponse = {
  __typename?: 'CancelSubscriptionResponse';
  periodEndDate?: Maybe<Scalars['String']>;
  subscription?: Maybe<Subscription>;
};

export type CategoriesResponse = {
  __typename?: 'CategoriesResponse';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<Category>>>;
};

export type CategoriesWhereInput = {
  id?: InputMaybe<IdFilter>;
  name?: InputMaybe<NestedStringFilter>;
  parent_category?: InputMaybe<CategoriesWhereInput>;
  slug?: InputMaybe<NestedStringFilter>;
};

export type CategoriesWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Category = {
  __typename?: 'Category';
  categories?: Maybe<Array<Maybe<Category>>>;
  created_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  parent_category?: Maybe<Category>;
  parent_category_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export enum CategoryMenu {
  Coahuila = 'COAHUILA',
  Economia = 'ECONOMIA',
  Expertos = 'EXPERTOS',
  Extremo = 'EXTREMO',
  Mexico = 'MEXICO',
  Politica = 'POLITICA',
  Saltillo = 'SALTILLO',
  Vida = 'VIDA',
  Vmas = 'VMAS'
}

export type CategoryOrderInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type CategoryRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<CategoriesWhereUniqueInput>>;
  create?: InputMaybe<Array<InputMaybe<CreateCategoryInput>>>;
};

export type CategoryRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<CategoriesWhereUniqueInput>>;
  create?: InputMaybe<Array<InputMaybe<CreateCategoryInput>>>;
  disconnect?: InputMaybe<Array<CategoriesWhereUniqueInput>>;
  set?: InputMaybe<Array<CategoriesWhereUniqueInput>>;
};

export type CategoryRelateToOneForCreateInput = {
  connect: CategoriesWhereUniqueInput;
};

export type CategoryRelateToOneForUpdateInput = {
  connect: CategoriesWhereUniqueInput;
};

export type Comment = {
  __typename?: 'Comment';
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  post?: Maybe<Post>;
  replies?: Maybe<Array<Maybe<ReplyComment>>>;
  status?: Maybe<CommentStatuses>;
  updated_at?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type CommentOrderInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type CommentRelateToOneForCreateInput = {
  connect?: InputMaybe<CommetsWhereUniqueInput>;
  create?: InputMaybe<CreateCommentInput>;
};

export enum CommentStatuses {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type CommentsResponse = {
  __typename?: 'CommentsResponse';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<Comment>>>;
};

export type CommentsWhereInput = {
  id?: InputMaybe<IdFilter>;
  message?: InputMaybe<NestedStringFilter>;
  posts?: InputMaybe<PostsWhereInput>;
};

export type CommetsWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Configuration = {
  __typename?: 'Configuration';
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  notification_push?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type ConnectResponse = {
  __typename?: 'ConnectResponse';
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type ConnectUserProviderInput = {
  token?: InputMaybe<Scalars['String']>;
};

export type Coupon = {
  __typename?: 'Coupon';
  assigned_to_share?: Maybe<Scalars['Boolean']>;
  coupon?: Maybe<Scalars['String']>;
  coupon_type?: Maybe<CouponTypes>;
  created_at?: Maybe<Scalars['String']>;
  discount_amount?: Maybe<Scalars['Int']>;
  discount_percent?: Maybe<Scalars['Int']>;
  duration_in_months?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  is_for_sharing?: Maybe<Scalars['Boolean']>;
  is_public?: Maybe<Scalars['Boolean']>;
  max_redemptions?: Maybe<Scalars['Int']>;
  paypal_coupon_plan?: Maybe<Scalars['String']>;
  plan?: Maybe<Plan>;
  plan_names?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['String']>;
  status?: Maybe<CouponStatuses>;
  stripe_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['String']>;
  used_at?: Maybe<Scalars['String']>;
};

export type CouponListResponse = {
  __typename?: 'CouponListResponse';
  count?: Maybe<Scalars['Int']>;
  coupons?: Maybe<Array<Maybe<Coupon>>>;
};

export type CouponListWhere = {
  requestType?: InputMaybe<CouponRequestType>;
  user_id?: InputMaybe<Scalars['Int']>;
};

export enum CouponRequestType {
  Assigned = 'ASSIGNED',
  Available = 'AVAILABLE',
  Used = 'USED'
}

export enum CouponStatuses {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum CouponTypes {
  DiscountAmount = 'DISCOUNT_AMOUNT',
  DiscountAmountAndDuration = 'DISCOUNT_AMOUNT_AND_DURATION',
  DiscountAmountAndDurationAndVolume = 'DISCOUNT_AMOUNT_AND_DURATION_AND_VOLUME',
  DiscountAmountAndDurationAndVolumeWithMonths = 'DISCOUNT_AMOUNT_AND_DURATION_AND_VOLUME_WITH_MONTHS',
  DiscountAmountAndDurationWithMonths = 'DISCOUNT_AMOUNT_AND_DURATION_WITH_MONTHS',
  DiscountAmountAndVolume = 'DISCOUNT_AMOUNT_AND_VOLUME',
  DiscountAmountAndVolumeWithMonths = 'DISCOUNT_AMOUNT_AND_VOLUME_WITH_MONTHS',
  DiscountAmountWithMonths = 'DISCOUNT_AMOUNT_WITH_MONTHS',
  DiscountPercent = 'DISCOUNT_PERCENT',
  DiscountPercentAndDuration = 'DISCOUNT_PERCENT_AND_DURATION',
  DiscountPercentAndDurationAndVolume = 'DISCOUNT_PERCENT_AND_DURATION_AND_VOLUME',
  DiscountPercentAndDurationAndVolumeWithMonths = 'DISCOUNT_PERCENT_AND_DURATION_AND_VOLUME_WITH_MONTHS',
  DiscountPercentAndDurationWithMonths = 'DISCOUNT_PERCENT_AND_DURATION_WITH_MONTHS',
  DiscountPercentAndVolume = 'DISCOUNT_PERCENT_AND_VOLUME',
  DiscountPercentAndVolumeWithMonths = 'DISCOUNT_PERCENT_AND_VOLUME_WITH_MONTHS',
  DiscountPercentWithMonths = 'DISCOUNT_PERCENT_WITH_MONTHS'
}

export type CreateAuthorInput = {
  description?: InputMaybe<Scalars['String']>;
  external_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CreateCategoryInput = {
  name?: InputMaybe<Scalars['String']>;
  parent_category?: InputMaybe<CategoryRelateToOneForCreateInput>;
  slug?: InputMaybe<Scalars['String']>;
};

export type CreateCommentInput = {
  message?: InputMaybe<Scalars['String']>;
  posts?: InputMaybe<PostRelateToOneForCreateInput>;
};

export type CreateContactRequest = {
  __typename?: 'CreateContactRequest';
  created_at?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
};

export type CreateContactRequestInput = {
  email?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
};

export type CreateMenuInput = {
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MenuStatus>;
};

export type CreateMenuItemInput = {
  menu_child?: InputMaybe<MenusRelateToOneForCreateInput>;
  menu_parent?: InputMaybe<MenusRelateToOneForCreateInput>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MenuStatus>;
  template?: InputMaybe<TemplatesRelateToOneForCreateInput>;
};

export type CreateMenuSectionInput = {
  author?: InputMaybe<AuthorRelateToOneForCreateInput>;
  category?: InputMaybe<CategoryRelateToOneForCreateInput>;
  menu_item?: InputMaybe<MenuItemRelateToOneForCreateInput>;
  menu_section_parent?: InputMaybe<MenuSectionRelateToOneForCreateInput>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  post_count?: InputMaybe<Scalars['Int']>;
  section_on_topics?: InputMaybe<SectionOnTopicsRelateToManyForCreateInput>;
};

export type CreateMenuSectionItemInput = {
  category?: InputMaybe<CategoryRelateToOneForCreateInput>;
  menu_section?: InputMaybe<MenuSectionsRelateToOneForCreateInput>;
  order?: InputMaybe<Scalars['Int']>;
};

export type CreateNotificationSectionInput = {
  notification_section_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

export type CreateNotificationSectionResponse = {
  __typename?: 'CreateNotificationSectionResponse';
  id?: Maybe<Scalars['Int']>;
};

export type CreateOneSignalNotificationInput = {
  onesignal_notification_screen_id?: InputMaybe<Scalars['Int']>;
  published_at?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<OneSignalNotificationStatus>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CreateOneSignalNotificationScreenInput = {
  name?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type CreatePaymentIntentClientSecretStripeInput = {
  plan_id: Scalars['Int'];
};

export type CreatePostImageInput = {
  source?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CreatePostInput = {
  content?: InputMaybe<Scalars['String']>;
  content_html?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  external_id?: InputMaybe<Scalars['Int']>;
  mobile_section?: InputMaybe<MobileSection>;
  post_image?: InputMaybe<PostImageRelateToOneForCreateInput>;
  posts_on_authors?: InputMaybe<PostsOnAuthorsRelateToManyForCreateInput>;
  posts_on_categories?: InputMaybe<PostsOnCategoriesRelateToManyForUpdateInput>;
  posts_on_topics?: InputMaybe<PostsOnTopicsRelateToManyForCreateInput>;
  published_at?: InputMaybe<Scalars['DateTime']>;
  reading_time?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<PostStatus>;
  summary?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
  url?: InputMaybe<Scalars['String']>;
};

export type CreatePostReactionInput = {
  post?: InputMaybe<PostRelateToOneForCreateInput>;
  reaction?: InputMaybe<ReactionRelateToOneForCreateInput>;
  user?: InputMaybe<UserRelateToOneForCreateInput>;
};

export type CreateReactionInput = {
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CreateReplyCommentInput = {
  comments?: InputMaybe<CommentRelateToOneForCreateInput>;
  message?: InputMaybe<Scalars['String']>;
  posts?: InputMaybe<PostRelateToOneForCreateInput>;
};

export type CreateSharedCouponResponse = {
  __typename?: 'CreateSharedCouponResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateStripeSubscriptionInput = {
  plan_id: Scalars['Int'];
  subscription_id: Scalars['String'];
  transaction_id: Scalars['String'];
};

export type CreateStripeSubscriptionResponse = {
  __typename?: 'CreateStripeSubscriptionResponse';
  authToken?: Maybe<Scalars['String']>;
  subscription?: Maybe<Subscription>;
};

export type CreateSubscriptionInput = {
  payment_method?: InputMaybe<PaymentMethod>;
  planId?: InputMaybe<Scalars['Int']>;
  transactionId?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

export type CreateSubscriptionResponse = {
  __typename?: 'CreateSubscriptionResponse';
  subscription_id?: Maybe<Scalars['Int']>;
};

export type CreateTopicInput = {
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  show_in_app?: InputMaybe<Scalars['Boolean']>;
};

export type CreateUserInput = {
  avatar?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  language?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  remember_token?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['String']>;
};

export type CreateUserPlaylistFolderInput = {
  name?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserRelateToOneForCreateInput>;
};

export type CreateUserPlaylistInput = {
  post?: InputMaybe<PostRelateToOneForCreateInput>;
  user?: InputMaybe<UserRelateToOneForCreateInput>;
  user_playlist_folder?: InputMaybe<UserPlaylistFolderRelateToOneForCreateInput>;
};

export type CreateUserPlaylistResponse = {
  __typename?: 'CreateUserPlaylistResponse';
  id?: Maybe<Scalars['Int']>;
};

export type CreateUserPlaylistTagResponse = {
  __typename?: 'CreateUserPlaylistTagResponse';
  id?: Maybe<Scalars['Int']>;
};

export type CreateUserSavedFolderInput = {
  name?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserRelateToOneForCreateInput>;
};

export type CreateUserSavedPostInput = {
  post?: InputMaybe<PostRelateToOneForCreateInput>;
  user?: InputMaybe<UserRelateToOneForCreateInput>;
  user_saved_post_folder?: InputMaybe<UserSavedPostFolderRelateToOneForCreateInput>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<DateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DeleteNotificationSectionInput = {
  notification_section_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

export type DeleteNotificationSectionResponse = {
  __typename?: 'DeleteNotificationSectionResponse';
  id?: Maybe<Scalars['Int']>;
};

export type DeleteUserPlaylistInput = {
  post_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

export type DeleteUserPlaylistResponse = {
  __typename?: 'DeleteUserPlaylistResponse';
  id?: Maybe<Scalars['Int']>;
};

export type DeleteUserPlaylistTagResponse = {
  __typename?: 'DeleteUserPlaylistTagResponse';
  id?: Maybe<Scalars['Int']>;
};

export type DeleteUserSavedFolderInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type DeleteUserSavedFolderResponse = {
  __typename?: 'DeleteUserSavedFolderResponse';
  saved_post_folder?: Maybe<UserSavedPostFolder>;
};

export type DeleteUserSavedPostInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type DisableAccountResponse = {
  __typename?: 'DisableAccountResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DisconnectResponse = {
  __typename?: 'DisconnectResponse';
  message?: Maybe<Scalars['String']>;
};

export type DisconnectUserProviderInput = {
  provider?: InputMaybe<SocialProviders>;
};

export type File = {
  encoding: Scalars['String'];
  filename: Scalars['String'];
  mimetype: Scalars['String'];
};

export type ForgotPasswordPayload = {
  __typename?: 'ForgotPasswordPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  NoApply = 'NO_APPLY'
}

export type HistoriesResponse = {
  __typename?: 'HistoriesResponse';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<History>>>;
};

export type History = {
  __typename?: 'History';
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  post_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<HistoryType>;
  updated_at?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
  users?: Maybe<Array<Maybe<User>>>;
};

export enum HistoryType {
  AddTopics = 'ADD_TOPICS',
  ChangePassword = 'CHANGE_PASSWORD',
  ChangePhoto = 'CHANGE_PHOTO',
  CommentPost = 'COMMENT_POST',
  DeleteTopics = 'DELETE_TOPICS',
  UpdateProfile = 'UPDATE_PROFILE',
  VisitPost = 'VISIT_POST'
}

export type HistoryWhere = {
  user_id?: InputMaybe<Scalars['Int']>;
};

export type IdFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<IdFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type Invoice = {
  __typename?: 'Invoice';
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  plan?: Maybe<Plan>;
  status?: Maybe<InvoiceStatuses>;
  transaction_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export enum InvoiceStatuses {
  Cancelled = 'CANCELLED',
  Paid = 'PAID',
  PaymentPending = 'PAYMENT_PENDING',
  Refunded = 'REFUNDED',
  Unpaid = 'UNPAID'
}

export type InvoicesByUser = {
  __typename?: 'InvoicesByUser';
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  total?: Maybe<Scalars['Int']>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Magazine = {
  __typename?: 'Magazine';
  category?: Maybe<MagazineCategory>;
  created_at?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  frequency?: Maybe<MagazineFrequency>;
  front_page?: Maybe<MagazineFrontPage>;
  id?: Maybe<Scalars['Int']>;
  pages?: Maybe<Array<Maybe<MagazinePage>>>;
  published_at?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type MagazineCategory = {
  __typename?: 'MagazineCategory';
  created_at?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type MagazineConnection = {
  __typename?: 'MagazineConnection';
  edges?: Maybe<Array<Maybe<MagazineConnectionNode>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type MagazineConnectionNode = {
  __typename?: 'MagazineConnectionNode';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Magazine>;
};

export enum MagazineFrequency {
  Annually = 'ANNUALLY',
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export type MagazineFrontPage = {
  __typename?: 'MagazineFrontPage';
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type MagazinePage = {
  __typename?: 'MagazinePage';
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export enum MagazineType {
  Magazine = 'MAGAZINE',
  Newspaper = 'NEWSPAPER'
}

export type MagazinesWhere = {
  frequency?: InputMaybe<MagazineFrequency>;
  id?: InputMaybe<Scalars['Int']>;
  published_at?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<MagazineType>;
};

export type MailchimpAddSubscriptionInput = {
  audience?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type MailchimpAddSubscriptionResponse = {
  __typename?: 'MailchimpAddSubscriptionResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type MailchimpAudience = {
  __typename?: 'MailchimpAudience';
  audience?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  is_subscribed?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type MailchimpAudienceResponse = {
  __typename?: 'MailchimpAudienceResponse';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<MailchimpAudience>>>;
};

export type MailchimpSubscription = {
  __typename?: 'MailchimpSubscription';
  audience?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  unique_email_id?: Maybe<Scalars['String']>;
};

export type MailchimpSubscriptionResponse = {
  __typename?: 'MailchimpSubscriptionResponse';
  subscriptions?: Maybe<Array<Maybe<MailchimpSubscription>>>;
};

export type MailchimpSubscriptionWhere = {
  email?: InputMaybe<Scalars['String']>;
};

export type Membership = {
  __typename?: 'Membership';
  address?: Maybe<Scalars['String']>;
  category?: Maybe<MembershipCategory>;
  company?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  recommend?: Maybe<MembershipRecommend>;
  status?: Maybe<MembershipStatus>;
  updated_at?: Maybe<Scalars['String']>;
};

export type MembershipAddRemoveResponse = {
  __typename?: 'MembershipAddRemoveResponse';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type MembershipCategory = {
  __typename?: 'MembershipCategory';
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type MembershipListConnection = {
  __typename?: 'MembershipListConnection';
  edges?: Maybe<Array<Maybe<MembershipListConnectionNode>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type MembershipListConnectionNode = {
  __typename?: 'MembershipListConnectionNode';
  node?: Maybe<Membership>;
};

export enum MembershipOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type MembershipRecommend = {
  __typename?: 'MembershipRecommend';
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  membership_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type MembershipRecommendation = {
  __typename?: 'MembershipRecommendation';
  id?: Maybe<Scalars['Int']>;
  membership_id?: Maybe<Scalars['Int']>;
};

export type MembershipResponse = {
  __typename?: 'MembershipResponse';
  items?: Maybe<Array<Maybe<Membership>>>;
};

export enum MembershipStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type MembershipWhere = {
  search?: InputMaybe<Scalars['String']>;
  status: MembershipStatus;
};

export type Menu = {
  __typename?: 'Menu';
  created_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  menu_items?: Maybe<Array<Maybe<MenuItem>>>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<MenuStatus>;
  type?: Maybe<MenuType>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type MenuItem = {
  __typename?: 'MenuItem';
  created_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  menu_child?: Maybe<Menu>;
  menu_child_id?: Maybe<Scalars['Int']>;
  menu_parent?: Maybe<Menu>;
  menu_parent_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  sections?: Maybe<Array<Maybe<MenuSection>>>;
  status?: Maybe<MenuStatus>;
  template?: Maybe<Template>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type MenuItemRelateToOneForCreateInput = {
  connect?: InputMaybe<MenusWhereUniqueInput>;
};

export type MenuItemRelateToOneForUpdateInput = {
  connect?: InputMaybe<MenusWhereUniqueInput>;
};

export type MenuItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type MenuOrderInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type MenuPostItem = {
  __typename?: 'MenuPostItem';
  posts?: Maybe<Array<Maybe<Post>>>;
  section?: Maybe<MenuSection>;
  section_internal?: Maybe<Array<Maybe<MenuPostItem>>>;
};

export type MenuSection = {
  __typename?: 'MenuSection';
  author?: Maybe<Author>;
  category?: Maybe<Category>;
  created_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  menu_section_parent_id?: Maybe<Scalars['Int']>;
  menu_sections?: Maybe<Array<Maybe<MenuSection>>>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  post_count?: Maybe<Scalars['Int']>;
  section_on_topics?: Maybe<Array<Maybe<SectionOnTopics>>>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type MenuSectionItemOrderInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type MenuSectionItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type MenuSectionItemsWhereInput = {
  id?: InputMaybe<IdFilter>;
  menu_section_id?: InputMaybe<IdFilter>;
  name?: InputMaybe<NestedStringFilter>;
};

export type MenuSectionOrderInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type MenuSectionRelateToOneForCreateInput = {
  connect?: InputMaybe<MenuSectionWhereUniqueInput>;
};

export type MenuSectionRelateToOneForUpdateInput = {
  connect?: InputMaybe<MenuSectionWhereUniqueInput>;
};

export type MenuSectionWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type MenuSectionsRelateToOneForCreateInput = {
  connect?: InputMaybe<MenuSectionItemWhereUniqueInput>;
};

export type MenuSectionsRelationFilterInput = {
  every?: InputMaybe<MenuSectionsWhereInput>;
  none?: InputMaybe<MenuSectionsWhereInput>;
  some?: InputMaybe<MenuSectionsWhereInput>;
};

export type MenuSectionsResponse = {
  __typename?: 'MenuSectionsResponse';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<MenuSection>>>;
};

export type MenuSectionsWhereInput = {
  id?: InputMaybe<IdFilter>;
  menu_section_parent?: InputMaybe<MenuSectionsWhereInput>;
  menus_id?: InputMaybe<IdFilter>;
  name?: InputMaybe<NestedStringFilter>;
};

export enum MenuStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum MenuType {
  Internal = 'INTERNAL',
  Principal = 'PRINCIPAL',
  Sections = 'SECTIONS'
}

export type MenusRelateToOneForCreateInput = {
  connect?: InputMaybe<MenusWhereUniqueInput>;
};

export type MenusRelateToOneForUpdateInput = {
  connect?: InputMaybe<MenusWhereUniqueInput>;
};

export type MenusResponse = {
  __typename?: 'MenusResponse';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<Menu>>>;
};

export type MenusWhereInput = {
  OR?: InputMaybe<Array<InputMaybe<MenusWhereInput>>>;
  id?: InputMaybe<IdFilter>;
  name?: InputMaybe<NestedStringFilter>;
  status?: InputMaybe<NestedStringFilter>;
  type?: InputMaybe<NestedStringFilter>;
};

export type MenusWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  sections?: InputMaybe<MenuSectionsRelationFilterInput>;
  status?: InputMaybe<MenuStatus>;
};

export enum MobileSection {
  Home = 'HOME',
  HomeFeatured = 'HOME_FEATURED'
}

export type Mutation = {
  __typename?: 'Mutation';
  addBusinessSuggestion?: Maybe<MembershipAddRemoveResponse>;
  addFavoriteMembership?: Maybe<MembershipAddRemoveResponse>;
  appleSignup?: Maybe<AuthPayload>;
  asyncPost?: Maybe<Post>;
  cancelSubscription?: Maybe<CancelSubscriptionResponse>;
  changePassword?: Maybe<AuthPayload>;
  connectUserApple?: Maybe<ConnectResponse>;
  connectUserFacebook?: Maybe<ConnectResponse>;
  connectUserGoogle?: Maybe<ConnectResponse>;
  createAuthor?: Maybe<Author>;
  createCategory?: Maybe<Category>;
  createComment?: Maybe<Comment>;
  createContactRequest?: Maybe<CreateContactRequest>;
  createMenu?: Maybe<Menu>;
  createMenuItem?: Maybe<MenuItem>;
  createMenuSection?: Maybe<MenuSection>;
  createOneSignalNotification?: Maybe<OneSignalNotification>;
  createOneSignalNotificationScreen?: Maybe<OneSignalNotificationScreen>;
  createPaymentIntentClientSecretStripe?: Maybe<CreatePaymentIntentClientSecretStripeResponse>;
  createPost?: Maybe<Post>;
  createPostReaction?: Maybe<Reaction>;
  createReaction?: Maybe<Reaction>;
  createReplyComment?: Maybe<ReplyComment>;
  createSharedCoupon?: Maybe<CreateSharedCouponResponse>;
  createStripeSubscription?: Maybe<CreateStripeSubscriptionResponse>;
  createTopic?: Maybe<Topic>;
  createUserPlaylist?: Maybe<CreateUserPlaylistResponse>;
  createUserPlaylistFolder?: Maybe<CreateUserPlaylistTagResponse>;
  createUserSavedFolder?: Maybe<UserSavedPostFolder>;
  createUserSavedPost?: Maybe<UserSavedPost>;
  deleteAuthor?: Maybe<Author>;
  deleteCategory?: Maybe<Category>;
  deleteMenu?: Maybe<Menu>;
  deleteMenuItem?: Maybe<MenuItem>;
  deleteMenuSection?: Maybe<MenuSection>;
  deleteOneSignalNotification?: Maybe<OneSignalNotification>;
  deleteOneSignalNotificationScreen?: Maybe<OneSignalNotificationScreen>;
  deletePost?: Maybe<Post>;
  deletePostReaction?: Maybe<Reaction>;
  deleteReaction?: Maybe<Reaction>;
  deleteTopic?: Maybe<Topic>;
  deleteUserPlaylist?: Maybe<UpdateUserPlaylistResponse>;
  deleteUserPlaylistFolder?: Maybe<DeleteUserPlaylistTagResponse>;
  deleteUserSavedFolder?: Maybe<UserSavedPostFolder>;
  deleteUserSavedPost?: Maybe<UserSavedPost>;
  disableAccount?: Maybe<DisableAccountResponse>;
  disconnectUserProvider?: Maybe<DisconnectResponse>;
  facebookSignup?: Maybe<AuthPayload>;
  forgotPassword?: Maybe<ForgotPasswordPayload>;
  forgotPasswordCms?: Maybe<ForgotPasswordPayload>;
  googleOneTabSignUp?: Maybe<AuthPayload>;
  googleSignIn?: Maybe<AuthPayload>;
  googleSignup?: Maybe<AuthPayload>;
  login?: Maybe<AuthPayload>;
  loginCMS?: Maybe<AuthPayload>;
  mailchimpAddSubscription?: Maybe<MailchimpAddSubscriptionResponse>;
  recaptcha?: Maybe<RecaptchaResponse>;
  removeFavoriteMembership?: Maybe<MembershipAddRemoveResponse>;
  signup?: Maybe<AuthPayload>;
  updateAuthor?: Maybe<Author>;
  updateCategory?: Maybe<Category>;
  updateManyMenuSection?: Maybe<MenuSection>;
  updateMenu?: Maybe<Menu>;
  updateMenuItem?: Maybe<MenuItem>;
  updateMenuSection?: Maybe<MenuSection>;
  updateOneSignalNotification?: Maybe<OneSignalNotification>;
  updateOneSignalNotificationScreen?: Maybe<OneSignalNotificationScreen>;
  updatePermissionScopes?: Maybe<Array<Maybe<RolesOnScopes>>>;
  updatePost?: Maybe<Post>;
  updatePostReaction?: Maybe<Reaction>;
  updateReaction?: Maybe<Reaction>;
  updateTopic?: Maybe<Topic>;
  updateUser?: Maybe<User>;
  updateUserPlaylist?: Maybe<DeleteUserPlaylistResponse>;
  updateUserPlaylistFolder?: Maybe<UpdateUserPlaylistTagResponse>;
  updateUserSavedFolder?: Maybe<UserSavedPostFolder>;
  updateUserSavedPost?: Maybe<UserSavedPost>;
};


export type MutationAddBusinessSuggestionArgs = {
  data?: InputMaybe<AddBusinessSuggestion>;
};


export type MutationAddFavoriteMembershipArgs = {
  membership_id: Scalars['Int'];
};


export type MutationAppleSignupArgs = {
  data: ProviderSignupInput;
};


export type MutationAsyncPostArgs = {
  where: PostsWhereUniqueInput;
};


export type MutationCancelSubscriptionArgs = {
  data?: InputMaybe<CancelSubscriptionInput>;
};


export type MutationChangePasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationConnectUserAppleArgs = {
  data: ConnectUserProviderInput;
};


export type MutationConnectUserFacebookArgs = {
  data: ConnectUserProviderInput;
};


export type MutationConnectUserGoogleArgs = {
  data: ConnectUserProviderInput;
};


export type MutationCreateAuthorArgs = {
  data: CreateAuthorInput;
};


export type MutationCreateCategoryArgs = {
  data: CreateCategoryInput;
};


export type MutationCreateCommentArgs = {
  data?: InputMaybe<CreateCommentInput>;
};


export type MutationCreateContactRequestArgs = {
  data?: InputMaybe<CreateContactRequestInput>;
};


export type MutationCreateMenuArgs = {
  data: CreateMenuInput;
};


export type MutationCreateMenuItemArgs = {
  data: CreateMenuItemInput;
};


export type MutationCreateMenuSectionArgs = {
  data: CreateMenuSectionInput;
  file?: InputMaybe<Scalars['Upload']>;
};


export type MutationCreateOneSignalNotificationArgs = {
  data: CreateOneSignalNotificationInput;
};


export type MutationCreateOneSignalNotificationScreenArgs = {
  data: CreateOneSignalNotificationScreenInput;
};


export type MutationCreatePaymentIntentClientSecretStripeArgs = {
  data: CreatePaymentIntentClientSecretStripeInput;
};


export type MutationCreatePostArgs = {
  data: CreatePostInput;
};


export type MutationCreatePostReactionArgs = {
  data?: InputMaybe<CreatePostReactionInput>;
};


export type MutationCreateReactionArgs = {
  data: CreateReactionInput;
  file?: InputMaybe<Scalars['Upload']>;
};


export type MutationCreateReplyCommentArgs = {
  data?: InputMaybe<CreateReplyCommentInput>;
};


export type MutationCreateSharedCouponArgs = {
  coupon_id: Scalars['Int'];
  emails?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationCreateStripeSubscriptionArgs = {
  data?: InputMaybe<CreateStripeSubscriptionInput>;
};


export type MutationCreateTopicArgs = {
  data: CreateTopicInput;
  file?: InputMaybe<Scalars['Upload']>;
};


export type MutationCreateUserPlaylistArgs = {
  data?: InputMaybe<CreateUserPlaylistInput>;
  where?: InputMaybe<UserPlaylistWhereUniqueInput>;
};


export type MutationCreateUserPlaylistFolderArgs = {
  data?: InputMaybe<CreateUserPlaylistFolderInput>;
};


export type MutationCreateUserSavedFolderArgs = {
  data?: InputMaybe<CreateUserSavedFolderInput>;
  file?: InputMaybe<Scalars['Upload']>;
};


export type MutationCreateUserSavedPostArgs = {
  data?: InputMaybe<CreateUserSavedPostInput>;
  where?: InputMaybe<UserSavedPostWhereUniqueInput>;
};


export type MutationDeleteAuthorArgs = {
  where: AuthorsWhereUniqueInput;
};


export type MutationDeleteCategoryArgs = {
  where: CategoriesWhereUniqueInput;
};


export type MutationDeleteMenuArgs = {
  where: MenusWhereUniqueInput;
};


export type MutationDeleteMenuItemArgs = {
  where: MenuItemWhereUniqueInput;
};


export type MutationDeleteMenuSectionArgs = {
  where: MenuSectionWhereUniqueInput;
};


export type MutationDeleteOneSignalNotificationArgs = {
  where?: InputMaybe<OneSignalNotificationWhereUniqueInput>;
};


export type MutationDeleteOneSignalNotificationScreenArgs = {
  where?: InputMaybe<OneSignalNotificationScreenWhereUniqueInput>;
};


export type MutationDeletePostArgs = {
  where: PostsWhereUniqueInput;
};


export type MutationDeletePostReactionArgs = {
  where?: InputMaybe<PostReactionWhereUniqueInput>;
};


export type MutationDeleteReactionArgs = {
  where: ReactionWhereUniqueInput;
};


export type MutationDeleteTopicArgs = {
  where: TopicsWhereUniqueInput;
};


export type MutationDeleteUserPlaylistArgs = {
  where?: InputMaybe<UserPlaylistWhereUniqueInput>;
};


export type MutationDeleteUserPlaylistFolderArgs = {
  where?: InputMaybe<UserPlaylistFolderWhereUniqueInput>;
};


export type MutationDeleteUserSavedFolderArgs = {
  where?: InputMaybe<UserSavedPostFolderWhereUniqueInput>;
};


export type MutationDeleteUserSavedPostArgs = {
  where?: InputMaybe<UserSavedPostWhereUniqueInput>;
};


export type MutationDisableAccountArgs = {
  where?: InputMaybe<UsersWhereInput>;
};


export type MutationDisconnectUserProviderArgs = {
  data: DisconnectUserProviderInput;
};


export type MutationFacebookSignupArgs = {
  data: ProviderSignupInput;
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
  form: Scalars['String'];
  redirectUrl: Scalars['String'];
};


export type MutationForgotPasswordCmsArgs = {
  email: Scalars['String'];
  form: Scalars['String'];
  redirectUrl: Scalars['String'];
};


export type MutationGoogleOneTabSignUpArgs = {
  data: ProviderSignupInput;
};


export type MutationGoogleSignInArgs = {
  data: ProviderSignupInput;
};


export type MutationGoogleSignupArgs = {
  data: ProviderSignupInput;
};


export type MutationLoginArgs = {
  data: LoginInput;
};


export type MutationLoginCmsArgs = {
  data: LoginInput;
};


export type MutationMailchimpAddSubscriptionArgs = {
  data?: InputMaybe<MailchimpAddSubscriptionInput>;
};


export type MutationRecaptchaArgs = {
  email: Scalars['String'];
  token: Scalars['String'];
};


export type MutationRemoveFavoriteMembershipArgs = {
  membership_id: Scalars['Int'];
};


export type MutationSignupArgs = {
  data: SignUpInput;
};


export type MutationUpdateAuthorArgs = {
  data: UpdateAuthorInput;
  where: AuthorsWhereUniqueInput;
};


export type MutationUpdateCategoryArgs = {
  data: UpdateCategoryInput;
  where: CategoriesWhereUniqueInput;
};


export type MutationUpdateManyMenuSectionArgs = {
  data?: InputMaybe<Array<InputMaybe<UpdateManyMenuSectionInput>>>;
};


export type MutationUpdateMenuArgs = {
  data: UpdateMenuInput;
  where: MenusWhereUniqueInput;
};


export type MutationUpdateMenuItemArgs = {
  data: UpdateMenuItemInput;
  where: MenuItemWhereUniqueInput;
};


export type MutationUpdateMenuSectionArgs = {
  data: UpdateMenuSectionInput;
  file?: InputMaybe<Scalars['Upload']>;
  where: MenuSectionWhereUniqueInput;
};


export type MutationUpdateOneSignalNotificationArgs = {
  data: UpdateOneSignalNotificationInput;
  where?: InputMaybe<OneSignalNotificationWhereUniqueInput>;
};


export type MutationUpdateOneSignalNotificationScreenArgs = {
  data: UpdateOneSignalNotificationScreenInput;
  where?: InputMaybe<OneSignalNotificationScreenWhereUniqueInput>;
};


export type MutationUpdatePermissionScopesArgs = {
  data?: InputMaybe<UpdatePermissionScopesInput>;
};


export type MutationUpdatePostArgs = {
  data: UpdatePostInput;
  where: PostsWhereUniqueInput;
};


export type MutationUpdatePostReactionArgs = {
  data?: InputMaybe<UpdatePostReactionInput>;
  where?: InputMaybe<PostReactionWhereUniqueInput>;
};


export type MutationUpdateReactionArgs = {
  data: UpdateReactionInput;
  file?: InputMaybe<Scalars['Upload']>;
  where?: InputMaybe<ReactionWhereUniqueInput>;
};


export type MutationUpdateTopicArgs = {
  data: UpdateTopicInput;
  file?: InputMaybe<Scalars['Upload']>;
  where: TopicsWhereUniqueInput;
};


export type MutationUpdateUserArgs = {
  data?: InputMaybe<UserUpdateInput>;
  file?: InputMaybe<Scalars['Upload']>;
  where: UserWhereUniqueInput;
};


export type MutationUpdateUserPlaylistArgs = {
  data?: InputMaybe<UpdateUserPlaylistInput>;
  where?: InputMaybe<UserPlaylistWhereUniqueInput>;
};


export type MutationUpdateUserPlaylistFolderArgs = {
  data?: InputMaybe<UpdateUserPlaylistFolderInput>;
  where?: InputMaybe<UserSavedPostFolderWhereUniqueInput>;
};


export type MutationUpdateUserSavedFolderArgs = {
  data?: InputMaybe<UpdateUserSavedFolderInput>;
  file?: InputMaybe<Scalars['Upload']>;
  where?: InputMaybe<UserSavedPostFolderWhereUniqueInput>;
};


export type MutationUpdateUserSavedPostArgs = {
  data?: InputMaybe<UserSavedPostsUpdateInput>;
  where?: InputMaybe<UserSavedPostWhereUniqueInput>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Newspaper = {
  __typename?: 'Newspaper';
  category?: Maybe<NewspaperCategory>;
  created_at?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  front_page?: Maybe<NewspaperFrontPage>;
  id?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type NewspaperCategory = {
  __typename?: 'NewspaperCategory';
  created_at?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type NewspaperConnection = {
  __typename?: 'NewspaperConnection';
  edges?: Maybe<Array<Maybe<NewspaperConnectionNode>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type NewspaperConnectionNode = {
  __typename?: 'NewspaperConnectionNode';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Newspaper>;
};

export type NewspaperFrontPage = {
  __typename?: 'NewspaperFrontPage';
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type NewspapersWhere = {
  id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

export type Notification = {
  __typename?: 'Notification';
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_read?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  post_id?: Maybe<Scalars['Int']>;
  section?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<NotificationType>;
  updated_at?: Maybe<Scalars['String']>;
};

export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  edges?: Maybe<Array<Maybe<NotificationConnectionNode>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type NotificationConnectionNode = {
  __typename?: 'NotificationConnectionNode';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Notification>;
};

export type NotificationListCount = {
  __typename?: 'NotificationListCount';
  count?: Maybe<Scalars['Int']>;
};

export enum NotificationType {
  BreakingNews = 'BREAKING_NEWS',
  Comment = 'COMMENT',
  Membership = 'MEMBERSHIP',
  Offers = 'OFFERS',
  Post = 'POST',
  Printed = 'PRINTED'
}

export type NotificationsWhere = {
  is_read?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<NotificationType>;
  user_id?: InputMaybe<Scalars['Int']>;
};

export type OneSignalNotification = {
  __typename?: 'OneSignalNotification';
  created_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  onesignal_notification_screen?: Maybe<OneSignalNotificationScreen>;
  onesignal_notification_screen_id?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['DateTime']>;
  status?: Maybe<OneSignalNotificationStatus>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type OneSignalNotificationOrderInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  published_at?: InputMaybe<SortOrder>;
};

export type OneSignalNotificationResponse = {
  __typename?: 'OneSignalNotificationResponse';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<OneSignalNotification>>>;
};

export type OneSignalNotificationScreen = {
  __typename?: 'OneSignalNotificationScreen';
  created_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type OneSignalNotificationScreenOrderInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type OneSignalNotificationScreenResponse = {
  __typename?: 'OneSignalNotificationScreenResponse';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<OneSignalNotificationScreen>>>;
};

export type OneSignalNotificationScreenWhereInput = {
  id?: InputMaybe<IdFilter>;
  name?: InputMaybe<NestedStringFilter>;
  url?: InputMaybe<NestedStringFilter>;
};

export type OneSignalNotificationScreenWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export enum OneSignalNotificationStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  Inactive = 'INACTIVE'
}

export type OneSignalNotificationWhereInput = {
  id?: InputMaybe<IdFilter>;
  published_at?: InputMaybe<DateTimeNullableFilter>;
  subtitle?: InputMaybe<NestedStringFilter>;
  title?: InputMaybe<NestedStringFilter>;
};

export type OneSignalNotificationWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export enum OrderComments {
  Asc = 'asc',
  Desc = 'desc'
}

export enum OrderMagazines {
  Asc = 'asc',
  Desc = 'desc'
}

export enum OrderNewspaper {
  Asc = 'asc',
  Desc = 'desc'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export enum PaymentMethod {
  ApplePay = 'APPLE_PAY',
  GooglePay = 'GOOGLE_PAY',
  Openpay = 'OPENPAY',
  Paypal = 'PAYPAL',
  StripeCard = 'STRIPE_CARD'
}

export type Permission = {
  __typename?: 'Permission';
  created_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<PermissionName>;
  scopes?: Maybe<Array<Maybe<Scope>>>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type PermissionBase = {
  __typename?: 'PermissionBase';
  created_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<PermissionName>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export enum PermissionName {
  Authors = 'AUTHORS',
  Categories = 'CATEGORIES',
  Invitations = 'INVITATIONS',
  Menus = 'MENUS',
  Posts = 'POSTS',
  Roles = 'ROLES',
  Topics = 'TOPICS',
  Users = 'USERS'
}

export type PermissionOrderInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export enum PermissionScope {
  Create = 'CREATE',
  Delete = 'DELETE',
  Read = 'READ',
  Update = 'UPDATE'
}

export type PermissionsResponse = {
  __typename?: 'PermissionsResponse';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<Permission>>>;
};

export type PermissionsWhereInput = {
  name?: InputMaybe<NestedStringFilter>;
  scopes?: InputMaybe<NestedStringFilter>;
};

export type Plan = {
  __typename?: 'Plan';
  description?: Maybe<Scalars['String']>;
  helper_text?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['String']>;
  status?: Maybe<PlanStatuses>;
  stripe?: Maybe<Stripe>;
  sub_title?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type PlanResponse = {
  __typename?: 'PlanResponse';
  items?: Maybe<Array<Maybe<Plan>>>;
};

export enum PlanStatuses {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type PlanWhereInput = {
  status?: InputMaybe<PlanStatuses>;
};

export type PlansWhereInput = {
  freeTrial?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<PlanStatuses>;
};

export type Post = {
  __typename?: 'Post';
  content?: Maybe<Scalars['String']>;
  content_html?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  external_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  mobile_section?: Maybe<MobileSection>;
  post_audio?: Maybe<PostAudio>;
  post_image?: Maybe<PostImage>;
  posts_on_authors?: Maybe<Array<Maybe<PostsOnAuthors>>>;
  posts_on_categories?: Maybe<Array<Maybe<PostsOnCategories>>>;
  posts_on_reactions?: Maybe<Array<Maybe<PostsOnReactions>>>;
  posts_on_topics?: Maybe<Array<Maybe<PostsOnTopics>>>;
  published_at?: Maybe<Scalars['DateTime']>;
  reading_time?: Maybe<Scalars['Int']>;
  status?: Maybe<PostStatus>;
  summary?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type PostAudio = {
  __typename?: 'PostAudio';
  created_at?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type PostImage = {
  __typename?: 'PostImage';
  created_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type PostImageRelateToOneForCreateInput = {
  connect?: InputMaybe<PostImagesWhereUniqueInput>;
  create?: InputMaybe<CreatePostImageInput>;
};

export type PostImagesWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type PostManyRelationFilter = {
  every?: InputMaybe<PostsWhereInput>;
  none?: InputMaybe<PostsWhereInput>;
  some?: InputMaybe<PostsWhereInput>;
};

export type PostOnAuthorsRelationFilterInput = {
  every?: InputMaybe<PostOnAuthorsWhereInput>;
  none?: InputMaybe<PostOnAuthorsWhereInput>;
  some?: InputMaybe<PostOnAuthorsWhereInput>;
};

export type PostOnAuthorsWhereInput = {
  AND?: InputMaybe<Array<InputMaybe<PostOnAuthorsWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<PostOnAuthorsWhereInput>>>;
  author?: InputMaybe<AuthorsWhereInput>;
  post?: InputMaybe<PostsWhereInput>;
};

export type PostOnCategoriesRelationFilterInput = {
  every?: InputMaybe<PostOnCategoriesWhereInput>;
  none?: InputMaybe<PostOnCategoriesWhereInput>;
  some?: InputMaybe<PostOnCategoriesWhereInput>;
};

export type PostOnCategoriesWhereInput = {
  AND?: InputMaybe<Array<InputMaybe<PostOnCategoriesWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<PostOnCategoriesWhereInput>>>;
  category?: InputMaybe<CategoriesWhereInput>;
};

export type PostOnTopicsRelationFilterInput = {
  every?: InputMaybe<PostOnTopicsWhereInput>;
  none?: InputMaybe<PostOnTopicsWhereInput>;
  some?: InputMaybe<PostOnTopicsWhereInput>;
};

export type PostOnTopicsWhereInput = {
  AND?: InputMaybe<Array<InputMaybe<PostOnTopicsWhereInput>>>;
  OR?: InputMaybe<Array<InputMaybe<PostOnTopicsWhereInput>>>;
  post?: InputMaybe<PostsWhereInput>;
  topic?: InputMaybe<TopicsWhereInput>;
};

export type PostOrderInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type PostReactionWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type PostRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<PostsWhereUniqueInput>>;
  create?: InputMaybe<Array<CreatePostInput>>;
};

export type PostRelateToOneForCreateInput = {
  connect?: InputMaybe<PostsWhereUniqueInput>;
  create?: InputMaybe<CreatePostInput>;
};

export type PostRelateToOneForUpdateInput = {
  connect?: InputMaybe<PostsWhereUniqueInput>;
  create?: InputMaybe<CreatePostInput>;
  disconnect?: InputMaybe<PostsWhereUniqueInput>;
  set?: InputMaybe<PostsWhereUniqueInput>;
};

export enum PostStatus {
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export type PostsIncludeInput = {
  post_audio?: InputMaybe<Scalars['Boolean']>;
  post_image?: InputMaybe<Scalars['Boolean']>;
};

export type PostsOnAuthorRelatedForCreateNestedInput = {
  connect?: InputMaybe<AuthorsWhereUniqueInput>;
};

export type PostsOnAuthors = {
  __typename?: 'PostsOnAuthors';
  author?: Maybe<Author>;
  post?: Maybe<Post>;
};

export type PostsOnAuthorsInput = {
  author_id?: InputMaybe<Scalars['Int']>;
  post_id?: InputMaybe<Scalars['Int']>;
};

export type PostsOnAuthorsRelateToManyForCreateInput = {
  create?: InputMaybe<Array<InputMaybe<PostsOnAuthorsRelatedForCreateInput>>>;
};

export type PostsOnAuthorsRelateToManyForUpdateInput = {
  create?: InputMaybe<Array<InputMaybe<PostsOnAuthorsRelatedForCreateInput>>>;
  delete?: InputMaybe<Array<InputMaybe<PostsOnAuthorsWhereUniqueInput>>>;
};

export type PostsOnAuthorsRelatedForCreateInput = {
  author?: InputMaybe<PostsOnAuthorRelatedForCreateNestedInput>;
};

export type PostsOnAuthorsWhereUniqueInput = {
  post_id_author_id?: InputMaybe<PostsOnAuthorsInput>;
};

export type PostsOnCategories = {
  __typename?: 'PostsOnCategories';
  category?: Maybe<Category>;
};

export type PostsOnCategoriesInput = {
  category_id?: InputMaybe<Scalars['Int']>;
  post_id?: InputMaybe<Scalars['Int']>;
};

export type PostsOnCategoriesRelateToManyForCreateInput = {
  create?: InputMaybe<Array<InputMaybe<PostsOnCategoryRelatedForCreateInput>>>;
};

export type PostsOnCategoriesRelateToManyForUpdateInput = {
  create?: InputMaybe<Array<InputMaybe<PostsOnCategoryRelatedForCreateInput>>>;
  delete?: InputMaybe<Array<InputMaybe<PostsOnCategoriesWhereUniqueInput>>>;
};

export type PostsOnCategoriesWhereUniqueInput = {
  post_id_category_id?: InputMaybe<PostsOnCategoriesInput>;
};

export type PostsOnCategoryRelatedForCreateInput = {
  category?: InputMaybe<PostsOnCategoryRelatedForCreateNestedInput>;
};

export type PostsOnCategoryRelatedForCreateNestedInput = {
  connect?: InputMaybe<CategoriesWhereUniqueInput>;
};

export type PostsOnReactions = {
  __typename?: 'PostsOnReactions';
  reaction?: Maybe<Reaction>;
};

export type PostsOnTopicRelatedForCreateInput = {
  topic?: InputMaybe<PostsOnTopicRelatedForCreateNestedInput>;
};

export type PostsOnTopicRelatedForCreateNestedInput = {
  connect?: InputMaybe<TopicsWhereUniqueInput>;
};

export type PostsOnTopics = {
  __typename?: 'PostsOnTopics';
  topic?: Maybe<Topic>;
};

export type PostsOnTopicsInput = {
  post_id?: InputMaybe<Scalars['Int']>;
  topic_id?: InputMaybe<Scalars['Int']>;
};

export type PostsOnTopicsRelateToManyForCreateInput = {
  create?: InputMaybe<Array<InputMaybe<PostsOnTopicRelatedForCreateInput>>>;
};

export type PostsOnTopicsRelateToManyForUpdateInput = {
  create?: InputMaybe<Array<InputMaybe<PostsOnTopicRelatedForCreateInput>>>;
  delete?: InputMaybe<Array<InputMaybe<PostsOnTopicsWhereUniqueInput>>>;
};

export type PostsOnTopicsWhereUniqueInput = {
  post_id_topic_id?: InputMaybe<PostsOnTopicsInput>;
};

export type PostsResponse = {
  __typename?: 'PostsResponse';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<Post>>>;
};

export type PostsWhereInput = {
  OR?: InputMaybe<Array<InputMaybe<PostsWhereInput>>>;
  content?: InputMaybe<NestedStringFilter>;
  external_id?: InputMaybe<IdFilter>;
  id?: InputMaybe<IdFilter>;
  posts_on_authors?: InputMaybe<PostOnAuthorsRelationFilterInput>;
  posts_on_categories?: InputMaybe<PostOnCategoriesRelationFilterInput>;
  posts_on_topics?: InputMaybe<PostOnTopicsRelationFilterInput>;
  title?: InputMaybe<NestedStringFilter>;
};

export type PostsWhereUniqueInput = {
  external_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

export type ProviderSignupInput = {
  token: Scalars['String'];
  url: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  author?: Maybe<Author>;
  authors?: Maybe<AuthorsResponse>;
  categories?: Maybe<CategoriesResponse>;
  comments?: Maybe<CommentsResponse>;
  currentUser?: Maybe<User>;
  fetchCoupon?: Maybe<Coupon>;
  fetchCouponList?: Maybe<CouponListResponse>;
  getInvoicesByUserId?: Maybe<InvoicesByUser>;
  getRolesOnScopes?: Maybe<RolesOnScopesResponse>;
  getStripePlans?: Maybe<StripePlansResponse>;
  getSubscriptionsByUserId?: Maybe<SubscriptionsByUser>;
  histories?: Maybe<HistoriesResponse>;
  magazines?: Maybe<MagazineConnection>;
  mailchimpAudiences?: Maybe<MailchimpAudienceResponse>;
  mailchimpSubscriptions?: Maybe<MailchimpSubscriptionResponse>;
  membershipList?: Maybe<MembershipListConnection>;
  menu?: Maybe<Menu>;
  menuItemWithPosts?: Maybe<Array<Maybe<MenuPostItem>>>;
  menuSections?: Maybe<MenuSectionsResponse>;
  menus?: Maybe<MenusResponse>;
  newspapers?: Maybe<NewspaperConnection>;
  notificationList?: Maybe<NotificationConnection>;
  notificationListCount?: Maybe<NotificationListCount>;
  oneSignalNotificationScreens?: Maybe<OneSignalNotificationScreenResponse>;
  oneSignalNotifications?: Maybe<OneSignalNotificationResponse>;
  permissions?: Maybe<PermissionsResponse>;
  plan?: Maybe<Plan>;
  plans?: Maybe<PlanResponse>;
  post?: Maybe<Post>;
  posts?: Maybe<PostsResponse>;
  reactions?: Maybe<ReactionsResponse>;
  roles?: Maybe<RolesResponse>;
  rssList?: Maybe<RssListResponse>;
  rssListByScreen?: Maybe<RssListByScreenResponse>;
  rssSections?: Maybe<RssSectionResponse>;
  searchPosts?: Maybe<PostsResponse>;
  searchTopics?: Maybe<TopicsResponse>;
  templates?: Maybe<TemplatesResponse>;
  topics?: Maybe<TopicsResponse>;
  userPlaylistFolders?: Maybe<UserPlaylistFoldersResponse>;
  userPlaylists?: Maybe<UserPlaylistsResponse>;
  userSavedPostFolders?: Maybe<UserSavedPostFoldersResponse>;
  userSavedPosts?: Maybe<UserSavedPostsResponse>;
  userTopics?: Maybe<UserSavedPostFoldersResponse>;
  users?: Maybe<UsersResponse>;
  validateCoupon?: Maybe<Coupon>;
};


export type QueryAuthorArgs = {
  where?: InputMaybe<AuthorsWhereInput>;
};


export type QueryAuthorsArgs = {
  orderBy?: InputMaybe<Array<AuthorOrderInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AuthorsWhereInput>;
};


export type QueryCategoriesArgs = {
  orderBy?: InputMaybe<Array<CategoryOrderInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CategoriesWhereInput>;
};


export type QueryCommentsArgs = {
  orderBy?: InputMaybe<Array<CommentOrderInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommentsWhereInput>;
};


export type QueryFetchCouponArgs = {
  couponCode: Scalars['String'];
  plan_id: Scalars['Int'];
};


export type QueryFetchCouponListArgs = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CouponListWhere>;
};


export type QueryGetInvoicesByUserIdArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};


export type QueryGetStripePlansArgs = {
  where?: InputMaybe<PlansWhereInput>;
};


export type QueryGetSubscriptionsByUserIdArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};


export type QueryHistoriesArgs = {
  where?: InputMaybe<HistoryWhere>;
};


export type QueryMagazinesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<OrderMagazines>;
  where?: InputMaybe<MagazinesWhere>;
};


export type QueryMailchimpSubscriptionsArgs = {
  where?: InputMaybe<MailchimpSubscriptionWhere>;
};


export type QueryMembershipListArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<MembershipOrder>;
  where?: InputMaybe<MembershipWhere>;
};


export type QueryMenuArgs = {
  where?: MenusWhereInput;
};


export type QueryMenuItemWithPostsArgs = {
  where?: MenuItemWhereUniqueInput;
};


export type QueryMenuSectionsArgs = {
  orderBy?: InputMaybe<Array<MenuSectionOrderInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MenuSectionsWhereInput>;
};


export type QueryMenusArgs = {
  orderBy?: InputMaybe<Array<MenuOrderInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MenusWhereInput>;
};


export type QueryNewspapersArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<OrderNewspaper>;
  where?: InputMaybe<NewspapersWhere>;
};


export type QueryNotificationListArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NotificationsWhere>;
};


export type QueryNotificationListCountArgs = {
  where?: InputMaybe<NotificationListCountWhere>;
};


export type QueryOneSignalNotificationScreensArgs = {
  orderBy?: InputMaybe<Array<OneSignalNotificationScreenOrderInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OneSignalNotificationScreenWhereInput>;
};


export type QueryOneSignalNotificationsArgs = {
  orderBy?: InputMaybe<Array<OneSignalNotificationOrderInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OneSignalNotificationWhereInput>;
};


export type QueryPermissionsArgs = {
  orderBy?: InputMaybe<Array<PermissionOrderInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PermissionsWhereInput>;
};


export type QueryPlanArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryPlansArgs = {
  where?: InputMaybe<PlansWhereInput>;
};


export type QueryPostArgs = {
  where?: InputMaybe<PostsWhereInput>;
};


export type QueryPostsArgs = {
  orderBy?: InputMaybe<Array<PostOrderInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostsWhereInput>;
};


export type QueryReactionsArgs = {
  orderBy?: InputMaybe<Array<ReactionOrderInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReactionsWhereInput>;
};


export type QueryRolesArgs = {
  orderBy?: InputMaybe<Array<RoleOrderInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RolesWhereInput>;
};


export type QueryRssListArgs = {
  where?: InputMaybe<RssListWhere>;
};


export type QueryRssListByScreenArgs = {
  where?: InputMaybe<RssListByScreenWhere>;
};


export type QuerySearchPostsArgs = {
  orderBy?: InputMaybe<Array<PostOrderInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SearchPostsWhereInput>;
};


export type QuerySearchTopicsArgs = {
  orderBy?: InputMaybe<Array<TopicOrderInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SearchTopicsWhereInput>;
};


export type QueryTemplatesArgs = {
  orderBy?: InputMaybe<Array<TemplateOrderInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TemplatesWhereInput>;
};


export type QueryTopicsArgs = {
  orderBy?: InputMaybe<Array<TopicOrderInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TopicsWhereInput>;
};


export type QueryUserPlaylistFoldersArgs = {
  include?: InputMaybe<UserPlaylistFoldersIncludeInput>;
  orderBy?: InputMaybe<Array<UserPlaylistFoldersOrderInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserPlaylistFoldersWhereInput>;
};


export type QueryUserPlaylistsArgs = {
  orderBy?: InputMaybe<Array<UserPlaylistsOrderInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserPlaylistsWhereInput>;
};


export type QueryUserSavedPostFoldersArgs = {
  include?: InputMaybe<UserSavedPostFoldersIncludeInput>;
  orderBy?: InputMaybe<Array<UserSavedPostFoldersOrderInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserSavedPostFoldersWhereInput>;
};


export type QueryUserSavedPostsArgs = {
  orderBy?: InputMaybe<Array<UserSavedPostsOrderInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserSavedPostsWhereInput>;
};


export type QueryUserTopicsArgs = {
  orderBy?: InputMaybe<Array<UserSavedPostFoldersOrderInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserSavedPostFoldersWhereInput>;
};


export type QueryUsersArgs = {
  orderBy?: InputMaybe<Array<UserOrderInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UsersWhereInput>;
};


export type QueryValidateCouponArgs = {
  couponCode: Scalars['String'];
  plan_id: Scalars['Int'];
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type Reaction = {
  __typename?: 'Reaction';
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  reaction_on_posts?: Maybe<Array<Maybe<ReactionOnPosts>>>;
  status?: Maybe<ReactionStatus>;
  updated_at?: Maybe<Scalars['String']>;
};

export type ReactionOnPosts = {
  __typename?: 'ReactionOnPosts';
  post?: Maybe<Post>;
};

export type ReactionOrderInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type ReactionRelateToOneForCreateInput = {
  connect?: InputMaybe<ReactionWhereUniqueInput>;
  create?: InputMaybe<CreateReactionInput>;
};

export type ReactionRelateToOneForUpdateInput = {
  connect?: InputMaybe<ReactionWhereUniqueInput>;
  create?: InputMaybe<CreateReactionInput>;
  disconnect?: InputMaybe<ReactionWhereUniqueInput>;
  set?: InputMaybe<ReactionWhereUniqueInput>;
};

export enum ReactionStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Inactive = 'INACTIVE'
}

export type ReactionWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type ReactionsResponse = {
  __typename?: 'ReactionsResponse';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<Reaction>>>;
};

export type ReactionsWhereInput = {
  id?: InputMaybe<IdFilter>;
  name?: InputMaybe<NestedStringFilter>;
};

export type RecaptchaResponse = {
  __typename?: 'RecaptchaResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type ReplyComment = {
  __typename?: 'ReplyComment';
  comment?: Maybe<Post>;
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  post?: Maybe<Post>;
  status?: Maybe<CommentStatuses>;
  updated_at?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type Role = {
  __typename?: 'Role';
  created_at?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<UserRoles>;
  roles_on_scopes?: Maybe<Array<Maybe<RolesOnScopes>>>;
  updated_at?: Maybe<Scalars['String']>;
};

export type RoleOrderInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type RolesOnScopes = {
  __typename?: 'RolesOnScopes';
  scope?: Maybe<Scope>;
};

export type RolesOnUsers = {
  __typename?: 'RolesOnUsers';
  role?: Maybe<Role>;
  role_id?: Maybe<Scalars['Int']>;
};

export type RolesResponse = {
  __typename?: 'RolesResponse';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<Role>>>;
};

export type RolesWhereInput = {
  id?: InputMaybe<IdFilter>;
  name?: InputMaybe<NestedStringFilter>;
};

export type RssCategoriesItem = {
  __typename?: 'RssCategoriesItem';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type RssItem = {
  __typename?: 'RssItem';
  author?: Maybe<RssItemAuthor>;
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  qualification?: Maybe<Scalars['String']>;
  sections?: Maybe<Array<Maybe<RssSectionsItem>>>;
  summary?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  topics?: Maybe<Array<Maybe<RssItemTopic>>>;
  url?: Maybe<Scalars['String']>;
};

export type RssItemAuthor = {
  __typename?: 'RssItemAuthor';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type RssItemTopic = {
  __typename?: 'RssItemTopic';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type RssListByScreenResponse = {
  __typename?: 'RssListByScreenResponse';
  featured?: Maybe<Array<Maybe<RssSectionItem>>>;
  lastNews?: Maybe<Array<Maybe<RssSectionItem>>>;
  sections?: Maybe<Array<Maybe<RssSectionItem>>>;
};

export type RssListByScreenWhere = {
  screen?: InputMaybe<RssListScreen>;
};

export enum RssListFormat {
  Json = 'json',
  Xml = 'xml'
}

export type RssListResponse = {
  __typename?: 'RssListResponse';
  items?: Maybe<Array<Maybe<RssItem>>>;
};

export enum RssListScreen {
  Coahuila = 'Coahuila',
  CoahuilaComunidades = 'CoahuilaComunidades',
  CoahuilaDerechosHumanos = 'CoahuilaDerechosHumanos',
  CoahuilaDerechosHumanosMedioAmbiente = 'CoahuilaDerechosHumanosMedioAmbiente',
  CoahuilaDerechosHumanosMinorias = 'CoahuilaDerechosHumanosMinorias',
  CoahuilaEducacion = 'CoahuilaEducacion',
  CoahuilaGobernador = 'CoahuilaGobernador',
  CoahuilaGobierno = 'CoahuilaGobierno',
  CoahuilaMunicipios = 'CoahuilaMunicipios',
  CoahuilaMunicipiosAcunaYPiedrasNegras = 'CoahuilaMunicipiosAcunaYPiedrasNegras',
  CoahuilaMunicipiosCuatroCienegas = 'CoahuilaMunicipiosCuatroCienegas',
  CoahuilaMunicipiosMonclova = 'CoahuilaMunicipiosMonclova',
  CoahuilaMunicipiosMuzquiz = 'CoahuilaMunicipiosMuzquiz',
  CoahuilaMunicipiosParrasYGeneral = 'CoahuilaMunicipiosParrasYGeneral',
  CoahuilaMunicipiosRamosYArteaga = 'CoahuilaMunicipiosRamosYArteaga',
  CoahuilaMunicipiosTorreon = 'CoahuilaMunicipiosTorreon',
  CoahuilaNearshoring = 'CoahuilaNearshoring',
  CoahuilaSalud = 'CoahuilaSalud',
  CoahuilaSeguridad = 'CoahuilaSeguridad',
  CoahuilaSemanario = 'CoahuilaSemanario',
  CoahuilaTransparencia = 'CoahuilaTransparencia',
  CoahuilaTurismo = 'CoahuilaTurismo',
  CoahuilaTurismoDinosaurios = 'CoahuilaTurismoDinosaurios',
  CoahuilaTurismoFerias = 'CoahuilaTurismoFerias',
  CoahuilaTurismoRutaDelRodeo = 'CoahuilaTurismoRutaDelRodeo',
  CoahuilaTurismoRutaDelVino = 'CoahuilaTurismoRutaDelVino',
  Economia = 'Economia',
  EconomiaBancos = 'EconomiaBancos',
  EconomiaDineroLocal = 'EconomiaDineroLocal',
  EconomiaEnActivo = 'EconomiaEnActivo',
  EconomiaFraude = 'EconomiaFraude',
  EconomiaIndustrias = 'EconomiaIndustrias',
  EconomiaMundo = 'EconomiaMundo',
  EconomiaNearshoring = 'EconomiaNearshoring',
  Expertos = 'Expertos',
  ExpertosALaVanguardia = 'ExpertosALaVanguardia',
  ExpertosArte = 'ExpertosArte',
  ExpertosDeportes = 'ExpertosDeportes',
  ExpertosDerechosHumanos = 'ExpertosDerechosHumanos',
  ExpertosEconomia = 'ExpertosEconomia',
  ExpertosEspectaculos = 'ExpertosEspectaculos',
  ExpertosFilosofiaYEducacion = 'ExpertosFilosofiaYEducacion',
  ExpertosGastronomia = 'ExpertosGastronomia',
  ExpertosVariedad = 'ExpertosVariedad',
  Extremo = 'Extremo',
  ExtremoDeportesLocales = 'ExtremoDeportesLocales',
  ExtremoDeportesLocalesFutbolAmericano = 'ExtremoDeportesLocalesFutbolAmericano',
  ExtremoDeportesLocalesFutbolSoccer = 'ExtremoDeportesLocalesFutbolSoccer',
  ExtremoDeportesLocalesGolf = 'ExtremoDeportesLocalesGolf',
  ExtremoDeportesLocalesPadel = 'ExtremoDeportesLocalesPadel',
  ExtremoFutbol = 'ExtremoFutbol',
  ExtremoFutbolFutbolInternacional = 'ExtremoFutbolFutbolInternacional',
  ExtremoFutbolFutbolNacional = 'ExtremoFutbolFutbolNacional',
  ExtremoMasDeportes = 'ExtremoMasDeportes',
  ExtremoNfl = 'ExtremoNFL',
  ExtremoPrevia = 'ExtremoPrevia',
  ExtremoResultados = 'ExtremoResultados',
  ExtremoSaraperos = 'ExtremoSaraperos',
  ExtremoSaraperosGuiaSarapera = 'ExtremoSaraperosGuiaSarapera',
  ExtremoSaraperosOrgulloSarapero = 'ExtremoSaraperosOrgulloSarapero',
  ExtremoViral = 'ExtremoViral',
  Hoy = 'Hoy',
  Mexico = 'Mexico',
  MexicoComunidades = 'MexicoComunidades',
  MexicoEstados = 'MexicoEstados',
  MexicoEstadosCiudadDeMexico = 'MexicoEstadosCiudadDeMexico',
  MexicoEstadosJalisco = 'MexicoEstadosJalisco',
  MexicoEstadosNuevoLeon = 'MexicoEstadosNuevoLeon',
  MexicoFechasImportantes = 'MexicoFechasImportantes',
  MexicoInseguradadFeminicidios = 'MexicoInseguradadFeminicidios',
  MexicoInseguridad = 'MexicoInseguridad',
  MexicoInseguridadCorrupcion = 'MexicoInseguridadCorrupcion',
  MexicoInseguridadCrimenOrganizado = 'MexicoInseguridadCrimenOrganizado',
  MexicoInseguridadViolenciaNacional = 'MexicoInseguridadViolenciaNacional',
  MexicoInstituciones = 'MexicoInstituciones',
  MexicoPresidenta = 'MexicoPresidenta',
  MexicoRelacionesExteriores = 'MexicoRelacionesExteriores',
  MexicoResumen = 'MexicoResumen',
  MexicoTendencias = 'MexicoTendencias',
  Politica = 'Politica',
  PoliticaCartones = 'PoliticaCartones',
  PoliticaCoahuila = 'PoliticaCoahuila',
  PoliticaEditorialVanguardia = 'PoliticaEditorialVanguardia',
  PoliticaMexico = 'PoliticaMexico',
  PoliticaMundo = 'PoliticaMundo',
  PoliticaOpinion = 'PoliticaOpinion',
  PoliticaPoliticon = 'PoliticaPoliticon',
  Saltillo = 'Saltillo',
  SaltilloAnunciosVialidad = 'SaltilloAnunciosVialidad',
  SaltilloBuenasNoticias = 'SaltilloBuenasNoticias',
  SaltilloBuenasNoticiasOrgulloLocal = 'SaltilloBuenasNoticiasOrgulloLocal',
  SaltilloBuenasNoticiasUtilidad = 'SaltilloBuenasNoticiasUtilidad',
  SaltilloCiertoFalso = 'SaltilloCiertoFalso',
  SaltilloColonias = 'SaltilloColonias',
  SaltilloColoniasReporteVecinal = 'SaltilloColoniasReporteVecinal',
  SaltilloDinero = 'SaltilloDinero',
  SaltilloGuiaLocal = 'SaltilloGuiaLocal',
  SaltilloGuiaLocalEncuestas = 'SaltilloGuiaLocalEncuestas',
  SaltilloGuiaLocalLoNuevo = 'SaltilloGuiaLocalLoNuevo',
  SaltilloGuiaLocalMapa = 'SaltilloGuiaLocalMapa',
  SaltilloGuiaLocalQueHacer = 'SaltilloGuiaLocalQueHacer',
  SaltilloHistoriasDeSaltillo = 'SaltilloHistoriasDeSaltillo',
  SaltilloSeguridad = 'SaltilloSeguridad',
  SaltilloSeguridadAccidentes = 'SaltilloSeguridadAccidentes',
  SaltilloSeguridadHechoViolentos = 'SaltilloSeguridadHechoViolentos',
  SaltilloSeguridadSeguimientos = 'SaltilloSeguridadSeguimientos',
  SaltilloTendencias = 'SaltilloTendencias',
  SaltilloZonaMetropolitana = 'SaltilloZonaMetropolitana',
  Vida = 'Vida',
  VidaBebidas = 'VidaBebidas',
  VidaConsejos = 'VidaConsejos',
  VidaConsejosOfertas = 'VidaConsejosOfertas',
  VidaConsejosTips = 'VidaConsejosTips',
  VidaGastronomia = 'VidaGastronomia',
  VidaHogar = 'VidaHogar',
  VidaMascotas = 'VidaMascotas',
  VidaSalud = 'VidaSalud',
  VidaTramites = 'VidaTramites',
  VidaTramitesPagos = 'VidaTramitesPagos',
  VidaViajes = 'VidaViajes',
  Vmas = 'Vmas',
  VmasArtes = 'VmasArtes',
  VmasArtesCulturaCoahuila = 'VmasArtesCulturaCoahuila',
  VmasArtesCulturaSaltillo = 'VmasArtesCulturaSaltillo',
  VmasArtesExpertosArte = 'VmasArtesExpertosArte',
  VmasCine = 'VmasCine',
  VmasConciertos = 'VmasConciertos',
  VmasEventos = 'VmasEventos',
  VmasEventosEventosCoahuila = 'VmasEventosEventosCoahuila',
  VmasEventosEventosSaltillo = 'VmasEventosEventosSaltillo',
  VmasExpertosVmas = 'VmasExpertosVmas',
  VmasShow = 'VmasShow',
  VmasStraming = 'VmasStraming',
  VmasTendencias = 'VmasTendencias'
}

export type RssListWhere = {
  format?: InputMaybe<RssListFormat>;
  url?: InputMaybe<Scalars['String']>;
};

export type RssSection = {
  __typename?: 'RssSection';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type RssSectionItem = {
  __typename?: 'RssSectionItem';
  posts?: Maybe<Array<Maybe<RssItem>>>;
  section?: Maybe<RssSection>;
};

export type RssSectionResponse = {
  __typename?: 'RssSectionResponse';
  items?: Maybe<Array<Maybe<RssSectionItem>>>;
  trending_sections?: Maybe<Array<Maybe<TrendingSection>>>;
};

export type RssSectionsItem = {
  __typename?: 'RssSectionsItem';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Scope = {
  __typename?: 'Scope';
  created_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  permission_id?: Maybe<Scalars['Int']>;
  permissions?: Maybe<PermissionBase>;
  scope?: Maybe<PermissionScope>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type SearchPostsWhereInput = {
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PostStatus>;
};

export type SearchTopicsWhereInput = {
  search?: InputMaybe<Scalars['String']>;
};

export type SectionOnTopicRelatedForCreateInput = {
  topic?: InputMaybe<SectionOnTopicRelatedForCreateNestedInput>;
};

export type SectionOnTopicRelatedForCreateNestedInput = {
  connect?: InputMaybe<TopicsWhereUniqueInput>;
};

export type SectionOnTopics = {
  __typename?: 'SectionOnTopics';
  topic?: Maybe<Topic>;
};

export type SectionOnTopicsInput = {
  section_id?: InputMaybe<Scalars['Int']>;
  topic_id?: InputMaybe<Scalars['Int']>;
};

export type SectionOnTopicsRelateToManyForCreateInput = {
  create?: InputMaybe<Array<InputMaybe<SectionOnTopicRelatedForCreateInput>>>;
};

export type SectionOnTopicsRelateToManyForUpdateInput = {
  create?: InputMaybe<Array<InputMaybe<SectionOnTopicRelatedForCreateInput>>>;
  delete?: InputMaybe<Array<InputMaybe<SectionOnTopicsWhereUniqueInput>>>;
};

export type SectionOnTopicsWhereUniqueInput = {
  section_id_topic_id?: InputMaybe<SectionOnTopicsInput>;
};

export type SignUpInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  url: Scalars['String'];
};

export enum SocialProviders {
  Apple = 'APPLE',
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE',
  GoogleOneTab = 'GOOGLE_ONE_TAB',
  System = 'SYSTEM'
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Stripe = {
  __typename?: 'Stripe';
  id?: Maybe<Scalars['Int']>;
  plan_id?: Maybe<Scalars['Int']>;
  price_id?: Maybe<Scalars['String']>;
  product_description?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['String']>;
  product_name?: Maybe<Scalars['String']>;
};

export type StripePlans = {
  __typename?: 'StripePlans';
  amount?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['Int']>;
  priceId?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
};

export type StripePlansResponse = {
  __typename?: 'StripePlansResponse';
  items?: Maybe<Array<Maybe<StripePlans>>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  cancel_date?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_cancel?: Maybe<Scalars['Boolean']>;
  payment_method?: Maybe<PaymentMethod>;
  period_end_date?: Maybe<Scalars['String']>;
  period_start_date?: Maybe<Scalars['String']>;
  plan?: Maybe<Plan>;
  status?: Maybe<SubscriptionStatuses>;
  updated_at?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export enum SubscriptionStatuses {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Inactive = 'INACTIVE'
}

export type SubscriptionsByUser = {
  __typename?: 'SubscriptionsByUser';
  subscriptions?: Maybe<Array<Maybe<Subscription>>>;
  total?: Maybe<Scalars['Int']>;
};

export type Template = {
  __typename?: 'Template';
  created_at?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<TemplateName>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export enum TemplateName {
  HomeScreen = 'HOME_SCREEN',
  SectionInternalScreen = 'SECTION_INTERNAL_SCREEN',
  SectionScreen = 'SECTION_SCREEN'
}

export type TemplateOrderInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type TemplatesRelateToOneForCreateInput = {
  connect?: InputMaybe<TemplatesWhereUniqueInput>;
};

export type TemplatesRelateToOneForUpdateInput = {
  connect?: InputMaybe<TemplatesWhereUniqueInput>;
};

export type TemplatesResponse = {
  __typename?: 'TemplatesResponse';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<Template>>>;
};

export type TemplatesWhereInput = {
  id?: InputMaybe<IdFilter>;
  name?: InputMaybe<NestedStringFilter>;
};

export type TemplatesWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Topic = {
  __typename?: 'Topic';
  created_at?: Maybe<Scalars['DateTime']>;
  external_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  show_in_app?: Maybe<Scalars['Boolean']>;
  status?: Maybe<TopicStatus>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type TopicOrderInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type TopicRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<TopicsWhereUniqueInput>>;
  create?: InputMaybe<Array<InputMaybe<CreateTopicInput>>>;
};

export type TopicRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<TopicsWhereUniqueInput>>;
  create?: InputMaybe<Array<InputMaybe<CreateTopicInput>>>;
  disconnect?: InputMaybe<Array<TopicsWhereUniqueInput>>;
  set?: InputMaybe<Array<TopicsWhereUniqueInput>>;
};

export enum TopicStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Inactive = 'INACTIVE'
}

export type TopicsResponse = {
  __typename?: 'TopicsResponse';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<Topic>>>;
};

export type TopicsWhereInput = {
  id?: InputMaybe<IdFilter>;
  name?: InputMaybe<NestedStringFilter>;
  show_in_app?: InputMaybe<Scalars['Boolean']>;
};

export type TopicsWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type TrendingSection = {
  __typename?: 'TrendingSection';
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export enum TypeBusiness {
  Own = 'OWN',
  ThirdParty = 'THIRD_PARTY'
}

export type UpdateAuthorInput = {
  description?: InputMaybe<Scalars['String']>;
  external_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateCategoryInput = {
  name?: InputMaybe<Scalars['String']>;
  parent_category?: InputMaybe<CategoryRelateToOneForUpdateInput>;
  slug?: InputMaybe<Scalars['String']>;
};

export type UpdateManyMenuSectionInput = {
  data?: InputMaybe<UpdateMenuSectionInput>;
  where?: InputMaybe<MenuSectionWhereUniqueInput>;
};

export type UpdateManyMenuSectionItemInput = {
  data?: InputMaybe<UpdateMenuSectionItemInput>;
  where?: InputMaybe<MenuSectionItemWhereUniqueInput>;
};

export type UpdateMenuInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateMenuItemInput = {
  menu_child?: InputMaybe<MenusRelateToOneForUpdateInput>;
  menu_parent?: InputMaybe<MenusRelateToOneForUpdateInput>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MenuStatus>;
  template?: InputMaybe<TemplatesRelateToOneForUpdateInput>;
};

export type UpdateMenuSectionInput = {
  author?: InputMaybe<AuthorRelateToOneForUpdateInput>;
  category?: InputMaybe<CategoryRelateToOneForUpdateInput>;
  menu_item?: InputMaybe<MenuItemRelateToOneForUpdateInput>;
  menu_section_parent?: InputMaybe<MenuSectionRelateToOneForUpdateInput>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  post_count?: InputMaybe<Scalars['Int']>;
  section_on_topics?: InputMaybe<SectionOnTopicsRelateToManyForUpdateInput>;
};

export type UpdateMenuSectionItemInput = {
  menu_section?: InputMaybe<MenuSectionsRelateToOneForCreateInput>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type UpdateOneSignalNotificationInput = {
  onesignal_notification_screen_id?: InputMaybe<Scalars['Int']>;
  published_at?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<OneSignalNotificationStatus>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateOneSignalNotificationScreenInput = {
  name?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type UpdatePermissionScopesInput = {
  role_id?: InputMaybe<Scalars['Int']>;
  scopes?: InputMaybe<Array<Scalars['Int']>>;
};

export type UpdatePostInput = {
  content?: InputMaybe<Scalars['String']>;
  content_html?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  external_id?: InputMaybe<Scalars['Int']>;
  mobile_section?: InputMaybe<MobileSection>;
  post_image?: InputMaybe<PostImageRelateToOneForCreateInput>;
  posts_on_authors?: InputMaybe<PostsOnAuthorsRelateToManyForUpdateInput>;
  posts_on_categories?: InputMaybe<PostsOnCategoriesRelateToManyForUpdateInput>;
  posts_on_topics?: InputMaybe<PostsOnTopicsRelateToManyForUpdateInput>;
  published_at?: InputMaybe<Scalars['DateTime']>;
  reading_time?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<PostStatus>;
  summary?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
  url?: InputMaybe<Scalars['String']>;
};

export type UpdatePostReactionInput = {
  post?: InputMaybe<PostRelateToOneForUpdateInput>;
  reaction?: InputMaybe<ReactionRelateToOneForCreateInput>;
  user?: InputMaybe<UserRelateToOneForUpdateInput>;
};

export type UpdateReactionInput = {
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ReactionStatus>;
};

export type UpdateTopicInput = {
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  show_in_app?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateUserPlaylistFolderInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateUserPlaylistInput = {
  user_playlist_folder?: InputMaybe<UserPlaylistFolderRelateToOneForCreateInput>;
};

export type UpdateUserPlaylistResponse = {
  __typename?: 'UpdateUserPlaylistResponse';
  id?: Maybe<Scalars['Int']>;
};

export type UpdateUserPlaylistTagResponse = {
  __typename?: 'UpdateUserPlaylistTagResponse';
  id?: Maybe<Scalars['Int']>;
};

export type UpdateUserSavedFolderInput = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  configuration?: Maybe<Configuration>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  gender?: Maybe<Gender>;
  gmt_created_at?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  language?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  roles_on_users?: Maybe<Array<Maybe<RolesOnUsers>>>;
  social_providers?: Maybe<Array<Maybe<UserSocialProviders>>>;
  status?: Maybe<UserStatus>;
  subscriptions?: Maybe<Array<Maybe<Subscription>>>;
  updated_at?: Maybe<Scalars['String']>;
  user_on_topics?: Maybe<Array<Maybe<UserOnTopics>>>;
};

export type UserMembershipFavorite = {
  __typename?: 'UserMembershipFavorite';
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  membership?: Maybe<Membership>;
  updated_at?: Maybe<Scalars['String']>;
};

export type UserMembershipFavorites = {
  __typename?: 'UserMembershipFavorites';
  items?: Maybe<Array<Maybe<UserMembershipFavorite>>>;
};

export type UserOnTopicRelatedForCreateInput = {
  topic?: InputMaybe<UserOnTopicRelatedForCreateNestedInput>;
};

export type UserOnTopicRelatedForCreateNestedInput = {
  connect?: InputMaybe<TopicsWhereUniqueInput>;
};

export type UserOnTopics = {
  __typename?: 'UserOnTopics';
  topic?: Maybe<Topic>;
};

export type UserOnTopicsInput = {
  topic_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

export type UserOnTopicsRelateToManyForUpdateInput = {
  create?: InputMaybe<Array<InputMaybe<UserOnTopicRelatedForCreateInput>>>;
  delete?: InputMaybe<Array<InputMaybe<UserOnTopicsWhereUniqueInput>>>;
};

export type UserOnTopicsWhereUniqueInput = {
  user_id_topic_id?: InputMaybe<UserOnTopicsInput>;
};

export type UserOrderInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type UserPlaylist = {
  __typename?: 'UserPlaylist';
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  post?: Maybe<Post>;
  updated_at?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  user_playlist_folder?: Maybe<UserPlaylistFolder>;
};

export type UserPlaylistFolder = {
  __typename?: 'UserPlaylistFolder';
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  user_playlists?: Maybe<Array<Maybe<UserPlaylist>>>;
};

export type UserPlaylistFolderRelateToOneForCreateInput = {
  connect?: InputMaybe<UserPlaylistFolderWhereUniqueInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type UserPlaylistFolderWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type UserPlaylistFoldersIncludeInput = {
  user_playlists?: InputMaybe<UserPlaylistFoldersUserPlaylistInput>;
};

export type UserPlaylistFoldersOrderInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type UserPlaylistFoldersResponse = {
  __typename?: 'UserPlaylistFoldersResponse';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<UserPlaylistFolder>>>;
};

export type UserPlaylistFoldersUserPlaylistInput = {
  include?: InputMaybe<UserPlaylistIncludeInput>;
  orderBy?: InputMaybe<Array<UserPlaylistOrderInput>>;
  take?: InputMaybe<Scalars['Int']>;
};

export type UserPlaylistFoldersWhereInput = {
  id?: InputMaybe<IdFilter>;
  user?: InputMaybe<UsersWhereInput>;
};

export type UserPlaylistIncludeInput = {
  post?: InputMaybe<UserPlaylistsPostsIncludeInput>;
};

export type UserPlaylistOrderInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type UserPlaylistWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  post?: InputMaybe<PostsWhereUniqueInput>;
  user?: InputMaybe<UsersWhereUniqueInput>;
};

export type UserPlaylistsOrderInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type UserPlaylistsPostsIncludeInput = {
  include?: InputMaybe<PostsIncludeInput>;
};

export type UserPlaylistsResponse = {
  __typename?: 'UserPlaylistsResponse';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<UserPlaylist>>>;
};

export type UserPlaylistsWhereInput = {
  post?: InputMaybe<PostsWhereInput>;
  user?: InputMaybe<UsersWhereInput>;
  user_playlist_folder_id?: InputMaybe<Scalars['Int']>;
};

export type UserRelateToOneForCreateInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
};

export type UserRelateToOneForUpdateInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  create?: InputMaybe<CreateUserInput>;
  disconnect?: InputMaybe<UserWhereUniqueInput>;
  set?: InputMaybe<UserWhereUniqueInput>;
};

export enum UserRoles {
  Administrator = 'ADMINISTRATOR',
  Editor = 'EDITOR',
  Guest = 'GUEST',
  Suscriptor = 'SUSCRIPTOR',
  User = 'USER'
}

export type UserSavedPost = {
  __typename?: 'UserSavedPost';
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  post?: Maybe<Post>;
  updated_at?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  user_saved_post_folder?: Maybe<UserSavedPostFolder>;
};

export type UserSavedPostFolder = {
  __typename?: 'UserSavedPostFolder';
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  user_saved_posts?: Maybe<Array<Maybe<UserSavedPost>>>;
};

export type UserSavedPostFolderRelateToOneForCreateInput = {
  connect?: InputMaybe<UserSavedPostFolderWhereUniqueInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type UserSavedPostFolderWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type UserSavedPostFoldersIncludeInput = {
  user_saved_posts?: InputMaybe<UserSavedPostFoldersUserSavesPostsInput>;
};

export type UserSavedPostFoldersOrderInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type UserSavedPostFoldersResponse = {
  __typename?: 'UserSavedPostFoldersResponse';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<UserSavedPostFolder>>>;
};

export type UserSavedPostFoldersUserSavesPostsInput = {
  include?: InputMaybe<UserSavesPostsIncludeInput>;
  orderBy?: InputMaybe<Array<UserSavedPostsOrderInput>>;
  take?: InputMaybe<Scalars['Int']>;
};

export type UserSavedPostFoldersWhereInput = {
  id?: InputMaybe<IdFilter>;
  user?: InputMaybe<UsersWhereInput>;
};

export type UserSavedPostWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  post?: InputMaybe<PostsWhereUniqueInput>;
  user?: InputMaybe<UsersWhereUniqueInput>;
};

export type UserSavedPostsOrderInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type UserSavedPostsResponse = {
  __typename?: 'UserSavedPostsResponse';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<UserSavedPost>>>;
};

export type UserSavedPostsUpdateInput = {
  user_saved_post_folder?: InputMaybe<UserSavedPostFolderRelateToOneForCreateInput>;
};

export type UserSavedPostsWhereInput = {
  post?: InputMaybe<PostsWhereInput>;
  user?: InputMaybe<UsersWhereInput>;
  user_saved_post_folder_id?: InputMaybe<Scalars['Int']>;
};

export type UserSavesPostsIncludeInput = {
  post?: InputMaybe<UserSavesPostsPostsIncludeInput>;
};

export type UserSavesPostsPostsIncludeInput = {
  include?: InputMaybe<PostsIncludeInput>;
};

export type UserSocialProviders = {
  __typename?: 'UserSocialProviders';
  created_at?: Maybe<Scalars['String']>;
  gmt_created_at?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  primary?: Maybe<Scalars['Boolean']>;
  provider?: Maybe<SocialProviders>;
  provider_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  url_register?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
  users?: Maybe<Array<Maybe<User>>>;
};

export enum UserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type UserUpdateInput = {
  avatar?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  language?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  remember_token?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<UserStatus>;
  updated_at?: InputMaybe<Scalars['String']>;
  user_on_topics?: InputMaybe<UserOnTopicsRelateToManyForUpdateInput>;
};

export type UserWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type UsersResponse = {
  __typename?: 'UsersResponse';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<User>>>;
};

export type UsersWhereInput = {
  created_at?: InputMaybe<DateTimeNullableFilter>;
  email?: InputMaybe<NestedStringFilter>;
  id?: InputMaybe<IdFilter>;
  name?: InputMaybe<NestedStringFilter>;
};

export type UsersWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type CreatePaymentIntentClientSecretStripeResponse = {
  __typename?: 'createPaymentIntentClientSecretStripeResponse';
  client_secret?: Maybe<Scalars['String']>;
  plan_id?: Maybe<Scalars['Int']>;
  subscription_id?: Maybe<Scalars['String']>;
};

export type NotificationListCountWhere = {
  is_read?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<NotificationType>;
  user_id?: InputMaybe<Scalars['Int']>;
};

export type RolesOnScopesResponse = {
  __typename?: 'rolesOnScopesResponse';
  permissions?: Maybe<Array<Maybe<Scalars['Int']>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CouponListQueryVariables = Exact<{
  where?: InputMaybe<CouponListWhere>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;


export type CouponListQuery = { __typename?: 'Query', fetchCouponList: { __typename?: 'CouponListResponse', count: number | null, coupons: Array<{ __typename?: 'Coupon', id: number, coupon: string | null, status: CouponStatuses | null, coupon_type: CouponTypes | null, discount_amount: number | null, discount_percent: number | null, start_date: string | null, end_date: string | null, max_redemptions: number | null, duration_in_months: number | null, is_for_sharing: boolean | null, used_at: string | null, assigned_to_share: boolean | null, plan_names: string | null, plan: { __typename?: 'Plan', title: string | null } | null } | null> | null } | null };

export type CreateSharedCouponMutationVariables = Exact<{
  couponId: Scalars['Int'];
  emails?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type CreateSharedCouponMutation = { __typename?: 'Mutation', createSharedCoupon: { __typename?: 'CreateSharedCouponResponse', success: boolean | null } | null };

export type FetchCouponQueryVariables = Exact<{
  couponCode: Scalars['String'];
  plan_id: Scalars['Int'];
}>;


export type FetchCouponQuery = { __typename?: 'Query', fetchCoupon: { __typename?: 'Coupon', id: number, coupon: string | null, paypal_coupon_plan: string | null, status: CouponStatuses | null, coupon_type: CouponTypes | null, discount_amount: number | null, discount_percent: number | null, start_date: string | null, end_date: string | null, max_redemptions: number | null, duration_in_months: number | null, is_for_sharing: boolean | null, used_at: string | null, assigned_to_share: boolean | null, plan: { __typename?: 'Plan', id: number | null, title: string | null } | null } | null };

export type ValidateCouponQueryVariables = Exact<{
  couponCode: Scalars['String'];
  plan_id: Scalars['Int'];
}>;


export type ValidateCouponQuery = { __typename?: 'Query', validateCoupon: { __typename?: 'Coupon', id: number, coupon: string | null, paypal_coupon_plan: string | null, status: CouponStatuses | null, coupon_type: CouponTypes | null, discount_amount: number | null, discount_percent: number | null, start_date: string | null, end_date: string | null, max_redemptions: number | null, duration_in_months: number | null, is_for_sharing: boolean | null, used_at: string | null, assigned_to_share: boolean | null, plan: { __typename?: 'Plan', id: number | null, title: string | null } | null } | null };

export type UserHistoryQueryVariables = Exact<{
  where?: InputMaybe<HistoryWhere>;
}>;


export type UserHistoryQuery = { __typename?: 'Query', histories: { __typename?: 'HistoriesResponse', count: number | null, items: Array<{ __typename?: 'History', id: number | null, type: HistoryType | null, title: string | null, message: string | null, created_at: string | null } | null> | null } | null };

export type GetInvoicesByUserIdQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetInvoicesByUserIdQuery = { __typename?: 'Query', getInvoicesByUserId: { __typename?: 'InvoicesByUser', total: number | null, invoices: Array<{ __typename?: 'Invoice', id: number | null, transaction_id: string | null, status: InvoiceStatuses | null, created_at: string | null, updated_at: string | null, plan: { __typename?: 'Plan', title: string | null, description: string | null, sub_title: string | null, price: string | null } | null } | null> | null } | null };

export type MailchimpAddSubscriptionMutationVariables = Exact<{
  data?: InputMaybe<MailchimpAddSubscriptionInput>;
}>;


export type MailchimpAddSubscriptionMutation = { __typename?: 'Mutation', mailchimpAddSubscription: { __typename?: 'MailchimpAddSubscriptionResponse', success: boolean | null } | null };

export type MailchimpAudiencesQueryVariables = Exact<{
  where?: InputMaybe<MailchimpSubscriptionWhere>;
}>;


export type MailchimpAudiencesQuery = { __typename?: 'Query', mailchimpAudiences: { __typename?: 'MailchimpAudienceResponse', count: number | null, items: Array<{ __typename?: 'MailchimpAudience', audience: string | null, id: number | null, is_subscribed: boolean | null, name: string | null, description: string | null } | null> | null } | null, mailchimpSubscriptions: { __typename?: 'MailchimpSubscriptionResponse', subscriptions: Array<{ __typename?: 'MailchimpSubscription', unique_email_id: string | null, status: string | null, audience: string | null } | null> | null } | null };

export type NotificationsQueryVariables = Exact<{
  where?: InputMaybe<NotificationsWhere>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
}>;


export type NotificationsQuery = { __typename?: 'Query', notificationList: { __typename?: 'NotificationConnection', edges: Array<{ __typename?: 'NotificationConnectionNode', node: { __typename?: 'Notification', id: number | null, message: string | null, created_at: string | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', totalCount: number | null, endCursor: string | null, hasPreviousPage: boolean, hasNextPage: boolean } | null } | null };

export type PlansQueryVariables = Exact<{
  where?: InputMaybe<PlansWhereInput>;
}>;


export type PlansQuery = { __typename?: 'Query', plans: { __typename?: 'PlanResponse', items: Array<{ __typename?: 'Plan', id: number | null, title: string | null, sub_title: string | null, price: string | null, description: string | null, helper_text: string | null, status: PlanStatuses | null, stripe: { __typename?: 'Stripe', id: number | null, product_id: string | null, price_id: string | null, product_name: string | null, product_description: string | null, plan_id: number | null } | null } | null> | null } | null };

export type PlanQueryVariables = Exact<{
  planId?: InputMaybe<Scalars['Int']>;
}>;


export type PlanQuery = { __typename?: 'Query', plan: { __typename?: 'Plan', id: number | null, title: string | null, sub_title: string | null, price: string | null, description: string | null, helper_text: string | null, status: PlanStatuses | null, stripe: { __typename?: 'Stripe', id: number | null, product_id: string | null, price_id: string | null, product_name: string | null, product_description: string | null, plan_id: number | null } | null } | null };

export type CancelSubscriptionMutationVariables = Exact<{
  data?: InputMaybe<CancelSubscriptionInput>;
}>;


export type CancelSubscriptionMutation = { __typename?: 'Mutation', cancelSubscription: { __typename?: 'CancelSubscriptionResponse', periodEndDate: string | null, subscription: { __typename?: 'Subscription', cancel_date: string | null, period_start_date: string | null, period_end_date: string | null, is_cancel: boolean | null } | null } | null };

export type GetSubscriptionByUserQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetSubscriptionByUserQuery = { __typename?: 'Query', getSubscriptionsByUserId: { __typename?: 'SubscriptionsByUser', total: number | null, subscriptions: Array<{ __typename?: 'Subscription', cancel_date: string | null, status: SubscriptionStatuses | null, payment_method: PaymentMethod | null, period_end_date: string | null, period_start_date: string | null, is_cancel: boolean | null, id: number | null, plan: { __typename?: 'Plan', title: string | null, description: string | null, sub_title: string | null, price: string | null } | null } | null> | null } | null };

export type SignupMutationVariables = Exact<{
  data: SignUpInput;
}>;


export type SignupMutation = { __typename?: 'Mutation', signup: { __typename?: 'AuthPayload', token: string | null, user: { __typename?: 'User', id: number } | null } | null };

export type GoogleSignupMutationVariables = Exact<{
  data: ProviderSignupInput;
}>;


export type GoogleSignupMutation = { __typename?: 'Mutation', googleSignup: { __typename?: 'AuthPayload', token: string | null, user: { __typename?: 'User', id: number } | null } | null };

export type RecaptchaMutationVariables = Exact<{
  email: Scalars['String'];
  token: Scalars['String'];
}>;


export type RecaptchaMutation = { __typename?: 'Mutation', recaptcha: { __typename?: 'RecaptchaResponse', success: boolean | null } | null };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', id: number, name: string, email: string, avatar: string | null, gender: Gender | null, birthday: string | null, country: string | null, city: string | null, language: string | null, social_providers: Array<{ __typename?: 'UserSocialProviders', id: number, provider: SocialProviders | null, provider_id: string | null, primary: boolean | null } | null> | null, roles_on_users: Array<{ __typename?: 'RolesOnUsers', role_id: number | null, role: { __typename?: 'Role', id: number, name: UserRoles | null } | null } | null> | null } | null };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  redirectUrl: Scalars['String'];
  form: Scalars['String'];
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword: { __typename?: 'ForgotPasswordPayload', success: boolean | null } | null };

export type ChangePasswordMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', changePassword: { __typename?: 'AuthPayload', token: string | null, user: { __typename?: 'User', id: number } | null } | null };

export type UpdateUserMutationVariables = Exact<{
  file?: InputMaybe<Scalars['Upload']>;
  data?: InputMaybe<UserUpdateInput>;
  where: UserWhereUniqueInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: number, name: string, email: string, avatar: string | null, gender: Gender | null, birthday: string | null, country: string | null, city: string | null, language: string | null } | null };

export type FacebookSignupMutationVariables = Exact<{
  data: ProviderSignupInput;
}>;


export type FacebookSignupMutation = { __typename?: 'Mutation', facebookSignup: { __typename?: 'AuthPayload', token: string | null, user: { __typename?: 'User', id: number } | null } | null };

export type AppleSignupMutationVariables = Exact<{
  data: ProviderSignupInput;
}>;


export type AppleSignupMutation = { __typename?: 'Mutation', appleSignup: { __typename?: 'AuthPayload', token: string | null, user: { __typename?: 'User', id: number } | null } | null };

export type LoginMutationVariables = Exact<{
  data: LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'AuthPayload', token: string | null, user: { __typename?: 'User', id: number } | null } | null };

export type ConnectUserGoogleMutationVariables = Exact<{
  data: ConnectUserProviderInput;
}>;


export type ConnectUserGoogleMutation = { __typename?: 'Mutation', connectUserGoogle: { __typename?: 'ConnectResponse', token: string | null, user: { __typename?: 'User', id: number, name: string, email: string } | null } | null };

export type DisconnectUserProviderMutationVariables = Exact<{
  data: DisconnectUserProviderInput;
}>;


export type DisconnectUserProviderMutation = { __typename?: 'Mutation', disconnectUserProvider: { __typename?: 'DisconnectResponse', message: string | null } | null };

export type ConnectUserFacebookMutationVariables = Exact<{
  data: ConnectUserProviderInput;
}>;


export type ConnectUserFacebookMutation = { __typename?: 'Mutation', connectUserFacebook: { __typename?: 'ConnectResponse', token: string | null, user: { __typename?: 'User', id: number, name: string, email: string } | null } | null };

export type ConnectUserAppleMutationVariables = Exact<{
  data: ConnectUserProviderInput;
}>;


export type ConnectUserAppleMutation = { __typename?: 'Mutation', connectUserApple: { __typename?: 'ConnectResponse', token: string | null, user: { __typename?: 'User', id: number, name: string, email: string } | null } | null };

export type UserSavedPostsQueryVariables = Exact<{
  where?: InputMaybe<UserSavedPostsWhereInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserSavedPostsOrderInput> | UserSavedPostsOrderInput>;
}>;


export type UserSavedPostsQuery = { __typename?: 'Query', userSavedPosts: { __typename?: 'UserSavedPostsResponse', count: number | null, items: Array<{ __typename?: 'UserSavedPost', id: number | null, created_at: string | null, updated_at: string | null, user: { __typename?: 'User', id: number, name: string, email: string, avatar: string | null } | null, post: { __typename?: 'Post', id: number | null, title: string | null, url: string | null, summary: string | null, reading_time: number | null, post_image: { __typename?: 'PostImage', title: string | null, subtitle: string | null, source: string | null } | null } | null, user_saved_post_folder: { __typename?: 'UserSavedPostFolder', id: number | null, name: string | null } | null } | null> | null } | null };

export type CreateUserSavedPostMutationVariables = Exact<{
  data?: InputMaybe<CreateUserSavedPostInput>;
}>;


export type CreateUserSavedPostMutation = { __typename?: 'Mutation', createUserSavedPost: { __typename?: 'UserSavedPost', id: number | null } | null };

export type DeleteUserSavedPostMutationVariables = Exact<{
  where?: InputMaybe<UserSavedPostWhereUniqueInput>;
}>;


export type DeleteUserSavedPostMutation = { __typename?: 'Mutation', deleteUserSavedPost: { __typename?: 'UserSavedPost', id: number | null } | null };

export type DisableAccountMutationVariables = Exact<{
  where?: InputMaybe<UsersWhereInput>;
}>;


export type DisableAccountMutation = { __typename?: 'Mutation', disableAccount: { __typename?: 'DisableAccountResponse', message: string | null, success: boolean | null } | null };


export const CouponListDocument = gql`
    query CouponList($where: CouponListWhere, $page: Int, $pageSize: Int) {
  fetchCouponList(where: $where, page: $page, pageSize: $pageSize) {
    count
    coupons {
      id
      coupon
      status
      coupon_type
      discount_amount
      discount_percent
      start_date
      end_date
      max_redemptions
      duration_in_months
      is_for_sharing
      used_at
      assigned_to_share
      plan_names
      plan {
        title
      }
    }
  }
}
    `;

/**
 * __useCouponListQuery__
 *
 * To run a query within a React component, call `useCouponListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useCouponListQuery(baseOptions?: Apollo.QueryHookOptions<CouponListQuery, CouponListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CouponListQuery, CouponListQueryVariables>(CouponListDocument, options);
      }
export function useCouponListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CouponListQuery, CouponListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CouponListQuery, CouponListQueryVariables>(CouponListDocument, options);
        }
export type CouponListQueryHookResult = ReturnType<typeof useCouponListQuery>;
export type CouponListLazyQueryHookResult = ReturnType<typeof useCouponListLazyQuery>;
export type CouponListQueryResult = Apollo.QueryResult<CouponListQuery, CouponListQueryVariables>;
export const CreateSharedCouponDocument = gql`
    mutation createSharedCoupon($couponId: Int!, $emails: [String!]) {
  createSharedCoupon(coupon_id: $couponId, emails: $emails) {
    success
  }
}
    `;
export type CreateSharedCouponMutationFn = Apollo.MutationFunction<CreateSharedCouponMutation, CreateSharedCouponMutationVariables>;

/**
 * __useCreateSharedCouponMutation__
 *
 * To run a mutation, you first call `useCreateSharedCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSharedCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSharedCouponMutation, { data, loading, error }] = useCreateSharedCouponMutation({
 *   variables: {
 *      couponId: // value for 'couponId'
 *      emails: // value for 'emails'
 *   },
 * });
 */
export function useCreateSharedCouponMutation(baseOptions?: Apollo.MutationHookOptions<CreateSharedCouponMutation, CreateSharedCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSharedCouponMutation, CreateSharedCouponMutationVariables>(CreateSharedCouponDocument, options);
      }
export type CreateSharedCouponMutationHookResult = ReturnType<typeof useCreateSharedCouponMutation>;
export type CreateSharedCouponMutationResult = Apollo.MutationResult<CreateSharedCouponMutation>;
export type CreateSharedCouponMutationOptions = Apollo.BaseMutationOptions<CreateSharedCouponMutation, CreateSharedCouponMutationVariables>;
export const FetchCouponDocument = gql`
    query fetchCoupon($couponCode: String!, $plan_id: Int!) {
  fetchCoupon(couponCode: $couponCode, plan_id: $plan_id) {
    id
    coupon
    paypal_coupon_plan
    status
    coupon_type
    discount_amount
    discount_percent
    start_date
    end_date
    max_redemptions
    duration_in_months
    is_for_sharing
    used_at
    assigned_to_share
    plan {
      id
      title
    }
  }
}
    `;

/**
 * __useFetchCouponQuery__
 *
 * To run a query within a React component, call `useFetchCouponQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCouponQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCouponQuery({
 *   variables: {
 *      couponCode: // value for 'couponCode'
 *      plan_id: // value for 'plan_id'
 *   },
 * });
 */
export function useFetchCouponQuery(baseOptions: Apollo.QueryHookOptions<FetchCouponQuery, FetchCouponQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchCouponQuery, FetchCouponQueryVariables>(FetchCouponDocument, options);
      }
export function useFetchCouponLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchCouponQuery, FetchCouponQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchCouponQuery, FetchCouponQueryVariables>(FetchCouponDocument, options);
        }
export type FetchCouponQueryHookResult = ReturnType<typeof useFetchCouponQuery>;
export type FetchCouponLazyQueryHookResult = ReturnType<typeof useFetchCouponLazyQuery>;
export type FetchCouponQueryResult = Apollo.QueryResult<FetchCouponQuery, FetchCouponQueryVariables>;
export const ValidateCouponDocument = gql`
    query validateCoupon($couponCode: String!, $plan_id: Int!) {
  validateCoupon(couponCode: $couponCode, plan_id: $plan_id) {
    id
    coupon
    paypal_coupon_plan
    status
    coupon_type
    discount_amount
    discount_percent
    start_date
    end_date
    max_redemptions
    duration_in_months
    is_for_sharing
    used_at
    assigned_to_share
    plan {
      id
      title
    }
  }
}
    `;

/**
 * __useValidateCouponQuery__
 *
 * To run a query within a React component, call `useValidateCouponQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateCouponQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateCouponQuery({
 *   variables: {
 *      couponCode: // value for 'couponCode'
 *      plan_id: // value for 'plan_id'
 *   },
 * });
 */
export function useValidateCouponQuery(baseOptions: Apollo.QueryHookOptions<ValidateCouponQuery, ValidateCouponQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateCouponQuery, ValidateCouponQueryVariables>(ValidateCouponDocument, options);
      }
export function useValidateCouponLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateCouponQuery, ValidateCouponQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateCouponQuery, ValidateCouponQueryVariables>(ValidateCouponDocument, options);
        }
export type ValidateCouponQueryHookResult = ReturnType<typeof useValidateCouponQuery>;
export type ValidateCouponLazyQueryHookResult = ReturnType<typeof useValidateCouponLazyQuery>;
export type ValidateCouponQueryResult = Apollo.QueryResult<ValidateCouponQuery, ValidateCouponQueryVariables>;
export const UserHistoryDocument = gql`
    query userHistory($where: HistoryWhere) {
  histories(where: $where) {
    items {
      id
      type
      title
      message
      created_at
    }
    count
  }
}
    `;

/**
 * __useUserHistoryQuery__
 *
 * To run a query within a React component, call `useUserHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserHistoryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUserHistoryQuery(baseOptions?: Apollo.QueryHookOptions<UserHistoryQuery, UserHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserHistoryQuery, UserHistoryQueryVariables>(UserHistoryDocument, options);
      }
export function useUserHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserHistoryQuery, UserHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserHistoryQuery, UserHistoryQueryVariables>(UserHistoryDocument, options);
        }
export type UserHistoryQueryHookResult = ReturnType<typeof useUserHistoryQuery>;
export type UserHistoryLazyQueryHookResult = ReturnType<typeof useUserHistoryLazyQuery>;
export type UserHistoryQueryResult = Apollo.QueryResult<UserHistoryQuery, UserHistoryQueryVariables>;
export const GetInvoicesByUserIdDocument = gql`
    query getInvoicesByUserId($limit: Int!, $offset: Int!) {
  getInvoicesByUserId(limit: $limit, offset: $offset) {
    invoices {
      id
      transaction_id
      status
      created_at
      updated_at
      plan {
        title
        description
        sub_title
        price
      }
    }
    total
  }
}
    `;

/**
 * __useGetInvoicesByUserIdQuery__
 *
 * To run a query within a React component, call `useGetInvoicesByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesByUserIdQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetInvoicesByUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetInvoicesByUserIdQuery, GetInvoicesByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoicesByUserIdQuery, GetInvoicesByUserIdQueryVariables>(GetInvoicesByUserIdDocument, options);
      }
export function useGetInvoicesByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoicesByUserIdQuery, GetInvoicesByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoicesByUserIdQuery, GetInvoicesByUserIdQueryVariables>(GetInvoicesByUserIdDocument, options);
        }
export type GetInvoicesByUserIdQueryHookResult = ReturnType<typeof useGetInvoicesByUserIdQuery>;
export type GetInvoicesByUserIdLazyQueryHookResult = ReturnType<typeof useGetInvoicesByUserIdLazyQuery>;
export type GetInvoicesByUserIdQueryResult = Apollo.QueryResult<GetInvoicesByUserIdQuery, GetInvoicesByUserIdQueryVariables>;
export const MailchimpAddSubscriptionDocument = gql`
    mutation MailchimpAddSubscription($data: MailchimpAddSubscriptionInput) {
  mailchimpAddSubscription(data: $data) {
    success
  }
}
    `;
export type MailchimpAddSubscriptionMutationFn = Apollo.MutationFunction<MailchimpAddSubscriptionMutation, MailchimpAddSubscriptionMutationVariables>;

/**
 * __useMailchimpAddSubscriptionMutation__
 *
 * To run a mutation, you first call `useMailchimpAddSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMailchimpAddSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mailchimpAddSubscriptionMutation, { data, loading, error }] = useMailchimpAddSubscriptionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMailchimpAddSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<MailchimpAddSubscriptionMutation, MailchimpAddSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MailchimpAddSubscriptionMutation, MailchimpAddSubscriptionMutationVariables>(MailchimpAddSubscriptionDocument, options);
      }
export type MailchimpAddSubscriptionMutationHookResult = ReturnType<typeof useMailchimpAddSubscriptionMutation>;
export type MailchimpAddSubscriptionMutationResult = Apollo.MutationResult<MailchimpAddSubscriptionMutation>;
export type MailchimpAddSubscriptionMutationOptions = Apollo.BaseMutationOptions<MailchimpAddSubscriptionMutation, MailchimpAddSubscriptionMutationVariables>;
export const MailchimpAudiencesDocument = gql`
    query MailchimpAudiences($where: MailchimpSubscriptionWhere) {
  mailchimpAudiences {
    count
    items {
      audience
      id
      is_subscribed
      name
      description
    }
  }
  mailchimpSubscriptions(where: $where) {
    subscriptions {
      unique_email_id
      status
      audience
    }
  }
}
    `;

/**
 * __useMailchimpAudiencesQuery__
 *
 * To run a query within a React component, call `useMailchimpAudiencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMailchimpAudiencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMailchimpAudiencesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useMailchimpAudiencesQuery(baseOptions?: Apollo.QueryHookOptions<MailchimpAudiencesQuery, MailchimpAudiencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MailchimpAudiencesQuery, MailchimpAudiencesQueryVariables>(MailchimpAudiencesDocument, options);
      }
export function useMailchimpAudiencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MailchimpAudiencesQuery, MailchimpAudiencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MailchimpAudiencesQuery, MailchimpAudiencesQueryVariables>(MailchimpAudiencesDocument, options);
        }
export type MailchimpAudiencesQueryHookResult = ReturnType<typeof useMailchimpAudiencesQuery>;
export type MailchimpAudiencesLazyQueryHookResult = ReturnType<typeof useMailchimpAudiencesLazyQuery>;
export type MailchimpAudiencesQueryResult = Apollo.QueryResult<MailchimpAudiencesQuery, MailchimpAudiencesQueryVariables>;
export const NotificationsDocument = gql`
    query Notifications($where: NotificationsWhere, $after: String, $first: Int) {
  notificationList(where: $where, after: $after, first: $first) {
    edges {
      node {
        id
        message
        created_at
      }
    }
    pageInfo {
      totalCount
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const PlansDocument = gql`
    query Plans($where: PlansWhereInput) {
  plans(where: $where) {
    items {
      id
      title
      sub_title
      price
      description
      helper_text
      status
      stripe {
        id
        product_id
        price_id
        product_name
        product_description
        plan_id
      }
    }
  }
}
    `;

/**
 * __usePlansQuery__
 *
 * To run a query within a React component, call `usePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlansQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePlansQuery(baseOptions?: Apollo.QueryHookOptions<PlansQuery, PlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlansQuery, PlansQueryVariables>(PlansDocument, options);
      }
export function usePlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlansQuery, PlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlansQuery, PlansQueryVariables>(PlansDocument, options);
        }
export type PlansQueryHookResult = ReturnType<typeof usePlansQuery>;
export type PlansLazyQueryHookResult = ReturnType<typeof usePlansLazyQuery>;
export type PlansQueryResult = Apollo.QueryResult<PlansQuery, PlansQueryVariables>;
export const PlanDocument = gql`
    query Plan($planId: Int) {
  plan(id: $planId) {
    id
    title
    sub_title
    price
    description
    helper_text
    status
    stripe {
      id
      product_id
      price_id
      product_name
      product_description
      plan_id
    }
  }
}
    `;

/**
 * __usePlanQuery__
 *
 * To run a query within a React component, call `usePlanQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function usePlanQuery(baseOptions?: Apollo.QueryHookOptions<PlanQuery, PlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanQuery, PlanQueryVariables>(PlanDocument, options);
      }
export function usePlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanQuery, PlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanQuery, PlanQueryVariables>(PlanDocument, options);
        }
export type PlanQueryHookResult = ReturnType<typeof usePlanQuery>;
export type PlanLazyQueryHookResult = ReturnType<typeof usePlanLazyQuery>;
export type PlanQueryResult = Apollo.QueryResult<PlanQuery, PlanQueryVariables>;
export const CancelSubscriptionDocument = gql`
    mutation CancelSubscription($data: CancelSubscriptionInput) {
  cancelSubscription(data: $data) {
    subscription {
      cancel_date
      period_start_date
      period_end_date
      is_cancel
    }
    periodEndDate
  }
}
    `;
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCancelSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>(CancelSubscriptionDocument, options);
      }
export type CancelSubscriptionMutationHookResult = ReturnType<typeof useCancelSubscriptionMutation>;
export type CancelSubscriptionMutationResult = Apollo.MutationResult<CancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;
export const GetSubscriptionByUserDocument = gql`
    query getSubscriptionByUser($limit: Int!, $offset: Int!) {
  getSubscriptionsByUserId(limit: $limit, offset: $offset) {
    subscriptions {
      plan {
        title
        description
        sub_title
        price
      }
      cancel_date
      status
      payment_method
      period_end_date
      period_start_date
      is_cancel
      id
    }
    total
  }
}
    `;

/**
 * __useGetSubscriptionByUserQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionByUserQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetSubscriptionByUserQuery(baseOptions: Apollo.QueryHookOptions<GetSubscriptionByUserQuery, GetSubscriptionByUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscriptionByUserQuery, GetSubscriptionByUserQueryVariables>(GetSubscriptionByUserDocument, options);
      }
export function useGetSubscriptionByUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptionByUserQuery, GetSubscriptionByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscriptionByUserQuery, GetSubscriptionByUserQueryVariables>(GetSubscriptionByUserDocument, options);
        }
export type GetSubscriptionByUserQueryHookResult = ReturnType<typeof useGetSubscriptionByUserQuery>;
export type GetSubscriptionByUserLazyQueryHookResult = ReturnType<typeof useGetSubscriptionByUserLazyQuery>;
export type GetSubscriptionByUserQueryResult = Apollo.QueryResult<GetSubscriptionByUserQuery, GetSubscriptionByUserQueryVariables>;
export const SignupDocument = gql`
    mutation Signup($data: SignUpInput!) {
  signup(data: $data) {
    user {
      id
    }
    token
  }
}
    `;
export type SignupMutationFn = Apollo.MutationFunction<SignupMutation, SignupMutationVariables>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSignupMutation(baseOptions?: Apollo.MutationHookOptions<SignupMutation, SignupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, options);
      }
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<SignupMutation, SignupMutationVariables>;
export const GoogleSignupDocument = gql`
    mutation googleSignup($data: ProviderSignupInput!) {
  googleSignup(data: $data) {
    user {
      id
    }
    token
  }
}
    `;
export type GoogleSignupMutationFn = Apollo.MutationFunction<GoogleSignupMutation, GoogleSignupMutationVariables>;

/**
 * __useGoogleSignupMutation__
 *
 * To run a mutation, you first call `useGoogleSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleSignupMutation, { data, loading, error }] = useGoogleSignupMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGoogleSignupMutation(baseOptions?: Apollo.MutationHookOptions<GoogleSignupMutation, GoogleSignupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GoogleSignupMutation, GoogleSignupMutationVariables>(GoogleSignupDocument, options);
      }
export type GoogleSignupMutationHookResult = ReturnType<typeof useGoogleSignupMutation>;
export type GoogleSignupMutationResult = Apollo.MutationResult<GoogleSignupMutation>;
export type GoogleSignupMutationOptions = Apollo.BaseMutationOptions<GoogleSignupMutation, GoogleSignupMutationVariables>;
export const RecaptchaDocument = gql`
    mutation Recaptcha($email: String!, $token: String!) {
  recaptcha(email: $email, token: $token) {
    success
  }
}
    `;
export type RecaptchaMutationFn = Apollo.MutationFunction<RecaptchaMutation, RecaptchaMutationVariables>;

/**
 * __useRecaptchaMutation__
 *
 * To run a mutation, you first call `useRecaptchaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecaptchaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recaptchaMutation, { data, loading, error }] = useRecaptchaMutation({
 *   variables: {
 *      email: // value for 'email'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRecaptchaMutation(baseOptions?: Apollo.MutationHookOptions<RecaptchaMutation, RecaptchaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecaptchaMutation, RecaptchaMutationVariables>(RecaptchaDocument, options);
      }
export type RecaptchaMutationHookResult = ReturnType<typeof useRecaptchaMutation>;
export type RecaptchaMutationResult = Apollo.MutationResult<RecaptchaMutation>;
export type RecaptchaMutationOptions = Apollo.BaseMutationOptions<RecaptchaMutation, RecaptchaMutationVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    id
    name
    email
    avatar
    gender
    birthday
    country
    city
    language
    social_providers {
      id
      provider
      provider_id
      primary
    }
    roles_on_users {
      role_id
      role {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($email: String!, $redirectUrl: String!, $form: String!) {
  forgotPassword(email: $email, redirectUrl: $redirectUrl, form: $form) {
    success
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      redirectUrl: // value for 'redirectUrl'
 *      form: // value for 'form'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($email: String!, $password: String!, $token: String!) {
  changePassword(email: $email, password: $password, token: $token) {
    user {
      id
    }
    token
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($file: Upload, $data: UserUpdateInput, $where: UserWhereUniqueInput!) {
  updateUser(file: $file, data: $data, where: $where) {
    id
    name
    email
    avatar
    gender
    birthday
    country
    city
    language
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      file: // value for 'file'
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const FacebookSignupDocument = gql`
    mutation FacebookSignup($data: ProviderSignupInput!) {
  facebookSignup(data: $data) {
    user {
      id
    }
    token
  }
}
    `;
export type FacebookSignupMutationFn = Apollo.MutationFunction<FacebookSignupMutation, FacebookSignupMutationVariables>;

/**
 * __useFacebookSignupMutation__
 *
 * To run a mutation, you first call `useFacebookSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFacebookSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [facebookSignupMutation, { data, loading, error }] = useFacebookSignupMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useFacebookSignupMutation(baseOptions?: Apollo.MutationHookOptions<FacebookSignupMutation, FacebookSignupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FacebookSignupMutation, FacebookSignupMutationVariables>(FacebookSignupDocument, options);
      }
export type FacebookSignupMutationHookResult = ReturnType<typeof useFacebookSignupMutation>;
export type FacebookSignupMutationResult = Apollo.MutationResult<FacebookSignupMutation>;
export type FacebookSignupMutationOptions = Apollo.BaseMutationOptions<FacebookSignupMutation, FacebookSignupMutationVariables>;
export const AppleSignupDocument = gql`
    mutation AppleSignup($data: ProviderSignupInput!) {
  appleSignup(data: $data) {
    user {
      id
    }
    token
  }
}
    `;
export type AppleSignupMutationFn = Apollo.MutationFunction<AppleSignupMutation, AppleSignupMutationVariables>;

/**
 * __useAppleSignupMutation__
 *
 * To run a mutation, you first call `useAppleSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppleSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appleSignupMutation, { data, loading, error }] = useAppleSignupMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAppleSignupMutation(baseOptions?: Apollo.MutationHookOptions<AppleSignupMutation, AppleSignupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AppleSignupMutation, AppleSignupMutationVariables>(AppleSignupDocument, options);
      }
export type AppleSignupMutationHookResult = ReturnType<typeof useAppleSignupMutation>;
export type AppleSignupMutationResult = Apollo.MutationResult<AppleSignupMutation>;
export type AppleSignupMutationOptions = Apollo.BaseMutationOptions<AppleSignupMutation, AppleSignupMutationVariables>;
export const LoginDocument = gql`
    mutation Login($data: LoginInput!) {
  login(data: $data) {
    user {
      id
    }
    token
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ConnectUserGoogleDocument = gql`
    mutation ConnectUserGoogle($data: ConnectUserProviderInput!) {
  connectUserGoogle(data: $data) {
    token
    user {
      id
      name
      email
    }
  }
}
    `;
export type ConnectUserGoogleMutationFn = Apollo.MutationFunction<ConnectUserGoogleMutation, ConnectUserGoogleMutationVariables>;

/**
 * __useConnectUserGoogleMutation__
 *
 * To run a mutation, you first call `useConnectUserGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectUserGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectUserGoogleMutation, { data, loading, error }] = useConnectUserGoogleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useConnectUserGoogleMutation(baseOptions?: Apollo.MutationHookOptions<ConnectUserGoogleMutation, ConnectUserGoogleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectUserGoogleMutation, ConnectUserGoogleMutationVariables>(ConnectUserGoogleDocument, options);
      }
export type ConnectUserGoogleMutationHookResult = ReturnType<typeof useConnectUserGoogleMutation>;
export type ConnectUserGoogleMutationResult = Apollo.MutationResult<ConnectUserGoogleMutation>;
export type ConnectUserGoogleMutationOptions = Apollo.BaseMutationOptions<ConnectUserGoogleMutation, ConnectUserGoogleMutationVariables>;
export const DisconnectUserProviderDocument = gql`
    mutation DisconnectUserProvider($data: DisconnectUserProviderInput!) {
  disconnectUserProvider(data: $data) {
    message
  }
}
    `;
export type DisconnectUserProviderMutationFn = Apollo.MutationFunction<DisconnectUserProviderMutation, DisconnectUserProviderMutationVariables>;

/**
 * __useDisconnectUserProviderMutation__
 *
 * To run a mutation, you first call `useDisconnectUserProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectUserProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectUserProviderMutation, { data, loading, error }] = useDisconnectUserProviderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDisconnectUserProviderMutation(baseOptions?: Apollo.MutationHookOptions<DisconnectUserProviderMutation, DisconnectUserProviderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisconnectUserProviderMutation, DisconnectUserProviderMutationVariables>(DisconnectUserProviderDocument, options);
      }
export type DisconnectUserProviderMutationHookResult = ReturnType<typeof useDisconnectUserProviderMutation>;
export type DisconnectUserProviderMutationResult = Apollo.MutationResult<DisconnectUserProviderMutation>;
export type DisconnectUserProviderMutationOptions = Apollo.BaseMutationOptions<DisconnectUserProviderMutation, DisconnectUserProviderMutationVariables>;
export const ConnectUserFacebookDocument = gql`
    mutation ConnectUserFacebook($data: ConnectUserProviderInput!) {
  connectUserFacebook(data: $data) {
    token
    user {
      id
      name
      email
    }
  }
}
    `;
export type ConnectUserFacebookMutationFn = Apollo.MutationFunction<ConnectUserFacebookMutation, ConnectUserFacebookMutationVariables>;

/**
 * __useConnectUserFacebookMutation__
 *
 * To run a mutation, you first call `useConnectUserFacebookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectUserFacebookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectUserFacebookMutation, { data, loading, error }] = useConnectUserFacebookMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useConnectUserFacebookMutation(baseOptions?: Apollo.MutationHookOptions<ConnectUserFacebookMutation, ConnectUserFacebookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectUserFacebookMutation, ConnectUserFacebookMutationVariables>(ConnectUserFacebookDocument, options);
      }
export type ConnectUserFacebookMutationHookResult = ReturnType<typeof useConnectUserFacebookMutation>;
export type ConnectUserFacebookMutationResult = Apollo.MutationResult<ConnectUserFacebookMutation>;
export type ConnectUserFacebookMutationOptions = Apollo.BaseMutationOptions<ConnectUserFacebookMutation, ConnectUserFacebookMutationVariables>;
export const ConnectUserAppleDocument = gql`
    mutation ConnectUserApple($data: ConnectUserProviderInput!) {
  connectUserApple(data: $data) {
    token
    user {
      id
      name
      email
    }
  }
}
    `;
export type ConnectUserAppleMutationFn = Apollo.MutationFunction<ConnectUserAppleMutation, ConnectUserAppleMutationVariables>;

/**
 * __useConnectUserAppleMutation__
 *
 * To run a mutation, you first call `useConnectUserAppleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectUserAppleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectUserAppleMutation, { data, loading, error }] = useConnectUserAppleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useConnectUserAppleMutation(baseOptions?: Apollo.MutationHookOptions<ConnectUserAppleMutation, ConnectUserAppleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectUserAppleMutation, ConnectUserAppleMutationVariables>(ConnectUserAppleDocument, options);
      }
export type ConnectUserAppleMutationHookResult = ReturnType<typeof useConnectUserAppleMutation>;
export type ConnectUserAppleMutationResult = Apollo.MutationResult<ConnectUserAppleMutation>;
export type ConnectUserAppleMutationOptions = Apollo.BaseMutationOptions<ConnectUserAppleMutation, ConnectUserAppleMutationVariables>;
export const UserSavedPostsDocument = gql`
    query UserSavedPosts($where: UserSavedPostsWhereInput, $take: Int, $skip: Int, $orderBy: [UserSavedPostsOrderInput!]) {
  userSavedPosts(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
    items {
      id
      user {
        id
        name
        email
        avatar
      }
      post {
        id
        title
        url
        post_image {
          title
          subtitle
          source
        }
        summary
        reading_time
      }
      user_saved_post_folder {
        id
        name
      }
      created_at
      updated_at
    }
    count
  }
}
    `;

/**
 * __useUserSavedPostsQuery__
 *
 * To run a query within a React component, call `useUserSavedPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSavedPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSavedPostsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useUserSavedPostsQuery(baseOptions?: Apollo.QueryHookOptions<UserSavedPostsQuery, UserSavedPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserSavedPostsQuery, UserSavedPostsQueryVariables>(UserSavedPostsDocument, options);
      }
export function useUserSavedPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserSavedPostsQuery, UserSavedPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserSavedPostsQuery, UserSavedPostsQueryVariables>(UserSavedPostsDocument, options);
        }
export type UserSavedPostsQueryHookResult = ReturnType<typeof useUserSavedPostsQuery>;
export type UserSavedPostsLazyQueryHookResult = ReturnType<typeof useUserSavedPostsLazyQuery>;
export type UserSavedPostsQueryResult = Apollo.QueryResult<UserSavedPostsQuery, UserSavedPostsQueryVariables>;
export const CreateUserSavedPostDocument = gql`
    mutation CreateUserSavedPost($data: CreateUserSavedPostInput) {
  createUserSavedPost(data: $data) {
    id
  }
}
    `;
export type CreateUserSavedPostMutationFn = Apollo.MutationFunction<CreateUserSavedPostMutation, CreateUserSavedPostMutationVariables>;

/**
 * __useCreateUserSavedPostMutation__
 *
 * To run a mutation, you first call `useCreateUserSavedPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserSavedPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserSavedPostMutation, { data, loading, error }] = useCreateUserSavedPostMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserSavedPostMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserSavedPostMutation, CreateUserSavedPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserSavedPostMutation, CreateUserSavedPostMutationVariables>(CreateUserSavedPostDocument, options);
      }
export type CreateUserSavedPostMutationHookResult = ReturnType<typeof useCreateUserSavedPostMutation>;
export type CreateUserSavedPostMutationResult = Apollo.MutationResult<CreateUserSavedPostMutation>;
export type CreateUserSavedPostMutationOptions = Apollo.BaseMutationOptions<CreateUserSavedPostMutation, CreateUserSavedPostMutationVariables>;
export const DeleteUserSavedPostDocument = gql`
    mutation DeleteUserSavedPost($where: UserSavedPostWhereUniqueInput) {
  deleteUserSavedPost(where: $where) {
    id
  }
}
    `;
export type DeleteUserSavedPostMutationFn = Apollo.MutationFunction<DeleteUserSavedPostMutation, DeleteUserSavedPostMutationVariables>;

/**
 * __useDeleteUserSavedPostMutation__
 *
 * To run a mutation, you first call `useDeleteUserSavedPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserSavedPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserSavedPostMutation, { data, loading, error }] = useDeleteUserSavedPostMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteUserSavedPostMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserSavedPostMutation, DeleteUserSavedPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserSavedPostMutation, DeleteUserSavedPostMutationVariables>(DeleteUserSavedPostDocument, options);
      }
export type DeleteUserSavedPostMutationHookResult = ReturnType<typeof useDeleteUserSavedPostMutation>;
export type DeleteUserSavedPostMutationResult = Apollo.MutationResult<DeleteUserSavedPostMutation>;
export type DeleteUserSavedPostMutationOptions = Apollo.BaseMutationOptions<DeleteUserSavedPostMutation, DeleteUserSavedPostMutationVariables>;
export const DisableAccountDocument = gql`
    mutation DisableAccount($where: UsersWhereInput) {
  disableAccount(where: $where) {
    message
    success
  }
}
    `;
export type DisableAccountMutationFn = Apollo.MutationFunction<DisableAccountMutation, DisableAccountMutationVariables>;

/**
 * __useDisableAccountMutation__
 *
 * To run a mutation, you first call `useDisableAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableAccountMutation, { data, loading, error }] = useDisableAccountMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDisableAccountMutation(baseOptions?: Apollo.MutationHookOptions<DisableAccountMutation, DisableAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableAccountMutation, DisableAccountMutationVariables>(DisableAccountDocument, options);
      }
export type DisableAccountMutationHookResult = ReturnType<typeof useDisableAccountMutation>;
export type DisableAccountMutationResult = Apollo.MutationResult<DisableAccountMutation>;
export type DisableAccountMutationOptions = Apollo.BaseMutationOptions<DisableAccountMutation, DisableAccountMutationVariables>;