import React, { useEffect } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { BENEFIT_SUBSCRIPTIONS, GOOGLE_CAPTCHA } from '../../shared/constans';
import { AuthResetPasswordForm } from './components/AuthResetPasswordForm';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { AuthForgotPassword } from './components/AuthForgotPassword';
import { useStore } from '@cobuildlab/react-simple-state';
import { authStepStore } from './auth-events';
import { getError } from './auth-utils';
import { AuthStep } from './auth-types';
import { MainLoader } from '../../shared/components/MainLoader';
import { useAuthRedirect } from './auth-hook';
import { AuthForgotPasswordNotFound } from './components/AuthForgotPasswordNotFound';
import { openDialogAlert } from '../alert/alert-actions';
import { AuthSwitch } from './components/AuthSwitch';
import { AuthSocialProviders } from './components/AuthSocialProviders';
import { AuthRegisterForm } from './components/AuthRegisterForm';
import { AuthLoginForm } from './components/AuthLoginForm';
import { SliderFeaturesPlanAuth } from '../../shared/components/slider/SliderFeaturesPlanAuth';
import { ImageAuth } from './components/ImageAuth';

export const Auth: React.FC = () => {
  const { step } = useStore(authStepStore);

  const { isAuthenticated } = useAuthRedirect('/');

  useEffect(() => {
    const error = getError();
    if (error) {
      openDialogAlert(error);
    }
  }, []);

  let content: JSX.Element;

  switch (step) {
    case AuthStep.authRegister:
      content = <AuthRegisterForm />;
      break;
    case AuthStep.authResetPassword:
      content = <AuthResetPasswordForm />;
      break;
    case AuthStep.authForgotPassword:
      content = <AuthForgotPassword />;
      break;
    case AuthStep.authForgotPasswordNotFound:
      content = <AuthForgotPasswordNotFound />;
      break;
    default:
      content = <AuthLoginForm />;

      break;
  }

  if (isAuthenticated) return <MainLoader />;
  let titleStep = '';
  switch (step) {
    case AuthStep.authLogin:
      titleStep = 'Inicia Sesión';
      break;
    case AuthStep.authRegister:
      titleStep = 'Regístrate';
      break;
  }
  const items = BENEFIT_SUBSCRIPTIONS.map((benefit, key) => (
    <Box
      //paddingLeft={{xs:'16px !important'}}
      paddingRight={{xs:'16px !important'}}
      key={'benefit' + key}
      maxHeight={{ xs: '150px', md: '155px' }}>
      <img
        src={benefit.image}
        alt="logo corona"
        width={'100%'}
        style={{
          objectFit: 'contain',
          background: '#EDEDED',
          borderRadius: '10px',
          overflow: 'hidden',
          maxHeight: '133px',
        }}
      />
      <Typography
        color={'black'}
        fontFamily={'Gotham'}
        fontSize={{ xs: 12, md: 12 }}>
        {benefit.label}
      </Typography>
    </Box>
  ));

  return (
    <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_CAPTCHA as string}>
      <Grid container minHeight={'100vh'} position={'relative'}>
        <Grid item xs={12} minHeight={'100%'}>
          <Grid container minHeight={'100%'}>
            <Grid
              display={{ xs: 'none', sm: 'block' }}
              visibility={{ xs: 'hidden', sm: 'visible' }}
              xs
              width={{ xs: 500, sm: '100%' }}
              minWidth={{ xs: 500, sm: 0 }}
              minHeight={{ xs: 400, sm: '100%' }}
              zIndex={1}
              position={'relative'}>
              <ImageAuth />
            </Grid>
            <Grid
              width={{ sm: 480 }}
              margin={{xs:'15px 15px !important', sm:'0px 0px !important'}}
              borderRadius={{xs:'10px',sm:'0px'}}
              overflow={'hidden'}
              minHeight={{xs:'100%',sm:'100%'}}
              zIndex={2}
              bgcolor={'white'}
              style={{ boxShadow: '0px 0px 32px 0px #00000099' }}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              flexDirection={'column'}
              item
              >
              <div style={{ flex: 1, background: '#f3f4f6' }} />
              {[AuthStep.authRegister, AuthStep.authLogin].includes(step) && (
                <>
                  <Box
                    marginBottom={{ xs: '20px', md: '20px' }}
                    paddingTop={'25px'}>
                    <img
                      src="/LogoVanguardiaMX.svg"
                      alt="logo vanguardia"
                      width={'214px'}
                      height={'20px'}
                      style={{ objectFit: 'contain', width: 214, height: 20 }}
                    />
                  </Box>
                </>
              )}
              {[AuthStep.authRegister].includes(step) && (
                <>
                  <Box
                    minWidth={{xs:'100%'}}
                    marginBottom={{ xs: '16px', md: '16px' }}>
                    <Typography fontFamily={'GothamBold'}  textAlign={'center'} paddingBottom={2}>
                      Conoce todos los beneficios de tu registro
                    </Typography>
                    <Box position={'relative'} width={'calc(100% - 16px)'} height={'165px'}>
                      <Box position={'absolute'} padding={{xs:'0px 16px !important'}} width={'100%'}  top={0} left={0}>
                        <SliderFeaturesPlanAuth infinite items={items} />
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
              <Box
                paddingX={{xs:3,sm:2}}
                style={{ background: 'white' }}
                width={{sm: 360 }}
                minWidth={{sm:320}}>
                <Typography
                  fontSize={{ xs: 20, md: 24 }}
                  marginBottom={2}
                  fontWeight={'bold'}
                  textAlign={'center'}>
                  {titleStep}
                </Typography>
                {[AuthStep.authRegister, AuthStep.authLogin].includes(step) && (
                  <>
                    <Box>
                      <AuthSocialProviders />
                    </Box>
                    <Box my={2}>
                      <Divider
                        sx={{
                          '&::before, &::after': {
                            borderColor: '#707070',
                          },
                          color: '#707070',
                        }}>
                        ó
                      </Divider>
                    </Box>
                  </>
                )}
                <Box>{content}</Box>
                {[AuthStep.authRegister, AuthStep.authLogin].includes(step) && (
                  <Box mt={2} paddingBottom={'25px'}>
                    <AuthSwitch />
                  </Box>
                )}
              </Box>
              <div style={{ flex: 1, background: '#f3f4f6' }} />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          display={{ xs: 'block', sm: 'none' }}
          visibility={{ xs: 'visible', sm: 'hidden' }}
          xs
          width={{ xs: '100%' }}
          height={{ xs: '100%' }}
          zIndex={1}
          position={'absolute'}>
          <ImageAuth />
        </Grid>
      </Grid>
    </GoogleReCaptchaProvider>
  );
};
