import { Box, Theme} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { sectionFourInfo } from './featureInfo';
import { SliderthreeElements } from '../../../shared/components/slider/SliderthreeElements';
import { PrizeCard } from './PrizeCard';
import { PlanSectionTitle } from '../../../shared/components/Text';
const useStyles = makeStyles<Theme>((theme: Theme) =>
    createStyles({
      imgVoice: {
        width: '100%',
        height:422,
        objectFit: 'cover',
        marginBottom:0,
        [theme.breakpoints.down('md')]: {
          height:290,
          marginBottom:10,
        },
        [theme.breakpoints.down('sm')]: {
            height:200,
            marginBottom:10,
          },
      },
      texts:{
        lineHeight: '28px !important',
        fontSize: '24px !important',
        [theme.breakpoints.down('md')]: {
            lineHeight: '24px !important',
            fontSize: '18px !important',
          },
      }
    }),
  );
export const PlansSectionFour : React.FC =() =>{
    const items = sectionFourInfo.map((item,index)=>
        <Box paddingX={1} key={index} display={'flex'} justifyContent={'center'}>
            <PrizeCard cardInfo={item} />
        </Box>
    );

    const classes = useStyles();
    return(
        <Box>
            <PlanSectionTitle className={classes.example} marginBottom={2}>
              Publicaciones premiadas
            </PlanSectionTitle>
            <SliderthreeElements items={items} infinite={false}/>
        </Box>
    );
};