import React from 'react';
import { fondosLayout } from '../../../shared/constans';
import { Box, Typography } from '@mui/material';

export const ImageAuth:React.FC=() => {
    const randomElement = fondosLayout[Math.floor(Math.random() * fondosLayout.length)];
    return(
        <>
            <img src={randomElement.image} width={'100%'} style={{objectFit:'cover',position:'absolute', height:'100%'}} alt='Imagen de presentación' />
              <Box
              position={'absolute'}
              bottom={16}
              left={16} 
              >
                <Box borderRadius={'8px'} minWidth={'90px'} px={'24px'} py={'16px'} bgcolor={'#FFFFFF80'} >
                  <Typography textAlign={'start'} fontSize={{xs:14,md:16}} fontFamily={'Gotham'}>Foto premiada</Typography>
                  <Typography textAlign={'start'} fontSize={{xs:16,md:24}} fontFamily={'PTSerif'} textTransform={'uppercase'}>{randomElement.author}</Typography>
                </Box>
              </Box>
        </>
    );
};