import { createStyles, makeStyles } from '@mui/styles';
import { Box, Grid, Theme, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Plan } from '../../../shared/types/generated';
import { useNavigate, useParams } from 'react-router-dom';
import { changePlanStoreAction } from '../payment-actions';
import { changePlanStore } from '../payment-events';
import { useStore } from '@cobuildlab/react-simple-state';
import { PaymentSelection } from '../../../shared/constans';
import { useAuth } from '../../auth/auth-hook';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { PRIMARY_COLOR } from '../../../shared/css/theme';
import { SelectDefault } from '../../../shared/components/input/SelectDefault';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid #999999',
      color: '#999999',
    },
    subtitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    box: {
      borderRadius: 5,
      boxShadow: '0px 3px 25px #00000012',
      justifyContent: 'center',
      border: '1px solid #999999',
      padding: '20px 30px',
    },
    contentCard: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 20,
    },
    card: {
      width: 150,
      borderRadius: 5,
      height: 90,
      display: 'flex',
      boxShadow: '0px 3px 25px #00000012',
      justifyContent: 'center',
      cursor: 'pointer',
      alignItems: 'center',
      margin: '0 5px',
    },

    cartText: { fontSize: 15, lineHeight: 15, marginLeft: 10 },
    notification: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '16px',
      border: '1px solid #999999',
    },
  }),
);

type PaymentMethodCardProps = {
  items?: Plan[] | null;
};

const PAYMENT_ITEMS = [
  {
    selected: PaymentSelection.OpenPay,
    src: '/card.svg',
    text: 'Tarjeta',
  },
  {
    selected: PaymentSelection.PayPal,
    src: '/paypal.svg',
    text: 'PayPal',
  },
  {
    selected: PaymentSelection.stripe,
    src: '/paypal.svg',
    text: 'PayPal',
  },
];
export const PaymentMethodCard: React.FC<PaymentMethodCardProps> = ({
  items = [],
}) => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const [selected, setSelected] = useState<PaymentSelection>(
    PaymentSelection.OpenPay,
  );
  const { plan } = useStore(changePlanStore);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (id && items) {
      const selectedPlan = items?.find((item) => item.id === parseInt(id));
      changePlanStoreAction(selectedPlan);
    } else {
      console.log('ERROR');
    }
  }, [id, items]);

  if (!user?.email) {
    return (
      <Box className={classes.notification}>
        <Typography variant="subtitle1" fontWeight={700} mb={1}>
          Para poder disfrutar de nuestros servicios agrega tu correo
          electrónico
        </Typography>
        <Typography variant="subtitle2" color="primary" mb={1}>
          Actualmente facebook cambio sus políticas de privacidad y limita la
          información de tu cuenta.
        </Typography>
        <ButtonDefault onClick={() => navigate('/')}>
          IR AL PERFIL
        </ButtonDefault>
      </Box>
    );
  }

  return (
    <>
      <Box className={classes.box} mb={2}>
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.title} mb={2}>
              <Typography fontSize={18}>Información detallada</Typography>
              <Typography fontSize={18}>Total</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.subtitle}>
              <SelectDefault
                variant="outlined"
                options={items?.map((item) => ({
                  label: item.title as string,
                  value: item.id?.toString() as string,
                }))}
                name={'plan'}
                defaultValue={id}
                onChangeData={(field, value) => {
                  const selectedPlan = items?.find(
                    (item) => item.id === parseInt(value),
                  );
                  changePlanStoreAction(selectedPlan);
                }}
                size="small"
              />
              <Typography fontWeight={700}>MXN ${plan?.price}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.box}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} md={5}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  style={{ fontSize: 16, fontWeight: 'bold' }}
                  align={'center'}
                  mb={2}
                >
                  Elige un método de pago:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.contentCard}>
                  {PAYMENT_ITEMS.map((item) => (
                    <Box
                      onClick={() => setSelected(item.selected)}
                      className={classes.card}
                      style={{
                        border:
                          selected === item.selected
                            ? `1px solid ${PRIMARY_COLOR}`
                            : 'none',
                      }}
                    >
                      <img
                        src={item.src}
                        alt="open pay"
                        width={28}
                        height={33}
                      />
                      <div className={classes.cartText}>{item.text}</div>
                    </Box>
                  ))}
                </div>
              </Grid>
              <Grid item xs={12}>
               
                {selected===PaymentSelection.stripe && <>Card</> }
              </Grid>
              <Grid item xs={12}>
                <Typography
                  style={{ fontSize: 15 }}
                  align={'center'}
                  mb={2}
                  paddingTop={'15px'}
                >
                  {plan?.helper_text}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
