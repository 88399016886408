import React, { useEffect, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import { Box, Theme} from '@mui/material';
import 'react-alice-carousel/lib/alice-carousel.css';
import { createStyles, makeStyles } from '@mui/styles';

type SliderProps = {
  items?: React.ReactNode[];
  infinite?: boolean;
  onlyOne?: boolean;
};

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    cleanButton: {
      border: 'none!important',
      cursor: 'pointer',
      outline: 'none!important',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);
export const SliderFeaturesPlanAuth: React.FC<SliderProps> = ({
  items,
  infinite,
  onlyOne = false,
}) => {
  const [responsive, setResponsive] = useState({});
  const [renderKey, setKey] = useState(0);

  useEffect(() => {
    setKey(Date.now());
  }, []);
  const classes = useStyles();
  useEffect(() => {
    setResponsive({
      0: {
        items: 2,
        itemsFit: 'contain',
      },
      520:{
        items: 2,
        itemsFit: 'contain',
      },
      720: {
        items: 3,
        itemsFit: 'contain',
      },
      1240: {
        items: 3,
        itemsFit: 'contain',
      },
    });
  }, []);
  //padding: '0 30px',
  return (
    <Box style={{ position: 'relative' }} paddingBottom={'20px'}>
      <div
        style={{
          position: 'relative',
          objectFit: 'contain',
        }}
      >
        <AliceCarousel
          renderKey={renderKey}
          autoHeight={onlyOne}
          autoWidth={onlyOne}
          mouseTracking
          items={items}
          responsive={responsive}
          infinite={infinite}
          autoPlay
          animationDuration={1000}
          autoPlayInterval={3000}
          disableButtonsControls
          renderPrevButton={(disabled)=>{
            if(disabled.isDisabled){
              return(
                null
              );
            }
            return(
              <Box position={'absolute'} bottom={'45%'} left={'-30px'}>
                <Box
                className={classes.cleanButton}

                >
                  <div style={{ display: 'flex', alignSelf: 'center' }}>
                    <img src={'/left-chevron.png'} alt="logo" width={30} height={30} />
                  </div>
                </Box>
              </Box>
            );
          }}
          renderNextButton={(disabled)=>{
            if(disabled.isDisabled){
              return(
                null
              );
            }
            return(
              <Box position={'absolute'} bottom={'45%'}  right={'-30px'}>
                <Box
                  className={classes.cleanButton}
                  >
                  <div style={{ display: 'flex', alignSelf: 'center' }}>
                    <img src={'/right-chevron.png'} alt="logo" width={30} height={30} />
                  </div>
                </Box>
              </Box>
            );
          }}
        />
        
      </div>
    </Box>
  );
};
