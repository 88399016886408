import React, { useEffect, useState } from 'react';
import { useAuth } from '../auth/auth-hook';
import { Typography } from '@mui/material';
import { CardSection } from '../../shared/components/card/CardSection';
import { TableDefault } from '../../shared/components/table/TableDefault';
import { TableColumnType } from '../../shared/components/table/table-types';
import { History, useUserHistoryLazyQuery } from '../../shared/types/generated';
import moment from 'moment';

export const HistoryEntries: React.FC = () => {
  const { user } = useAuth();
  const [count, setCount] = useState(0);
  const [pagination] = useState({
    pageSize: 5,
    page: 1,
  });

  const [callAction, { data, loading }] = useUserHistoryLazyQuery({
    onCompleted: (res) => {
      setCount(res?.histories?.count|| 0);
    },
  });

  useEffect(() => {
    callAction({
      variables: {
        where: {
          user_id: user?.id,
        },
      },
    });
  }, [user?.id, pagination.page, pagination.pageSize, callAction]);

  const historyEntry = data?.histories?.items || [];

  //const invoices = user?.invoices || [];
  const COLUMNS: TableColumnType<History>[] = [
    {
      columnName: 'Fecha',
      columnValue: (item: History) =>
        moment(parseInt(item?.created_at as string)).format('DD-MM-YYYY'),
    },
    {
      columnName: 'Acción',
      columnValue: (item: History) => item?.title as string,
    },
    {
      columnName: 'Mensaje',
      columnValue: (item: History) => item?.message as string,
    },
  ];

  return (
    <CardSection
      title={
        <Typography variant="h6" fontWeight={800}>
          Historial
        </Typography>
      }
      subheader={
        <Typography variant="subtitle1">
          Acá encontrarás información acerca de tus acciones realizadas en este portal.
        </Typography>
      }
    >
      <TableDefault
        count={count}
        items={historyEntry as History[]}
        columns={COLUMNS}
        page={1}
        pageSize={10}
        loading={loading}
        messageLoading={'Cargando historial...'}
        showPagination
      />
    </CardSection>
  );
};
