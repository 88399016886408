import React from 'react';
import { styled } from '@mui/styles';
import { Button, Typography } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import { useCookies } from 'react-cookie';
import { useGoogleSignupMutation } from '../../../shared/types/generated';
import { COOKIES_OPTIONS, USER_COOKIE } from '../../../shared/constans';
import { redirectToHome } from '../../../shared/utils';
import { createProvider, getCurrentUrl } from '../auth-utils';
import { openDialogAlert } from '../../alert/alert-actions';
import { useLocation } from 'react-router-dom';


const StyledButton = styled(Button)({
  position: 'relative',
  justifyContent: 'center!important',
  borderRadius: '5px!important',
  height: '38px !important',
  paddingRight: '30px!important',
  border: '1px solid #000000!important',
  backgroundColor: 'white !important',
  color:  '#999999 !important',
});

const StyledImage = styled('img')({
  marginRight: 10,
});

export const GoogleButton: React.FC = () => {
  const location = useLocation();
  const returnTo = location?.state?.returnTo as string | undefined;
  const [, setCookie] = useCookies([USER_COOKIE, 'analytics_uuid']);

  const [googleSignupCallback] = useGoogleSignupMutation({
    onCompleted: ({ googleSignup }) => {
      setCookie(USER_COOKIE, googleSignup?.token, COOKIES_OPTIONS);
      createProvider('Google');
      redirectToHome(returnTo ?? '/');
    },
    onError: () =>
      openDialogAlert('Tenemos problemas para identificar el usuario google'),
  });

  const googleLogin = useGoogleLogin({

    onSuccess: ({ access_token }) => {
      googleSignupCallback({
        variables: {
          data:{
            token: access_token,
            url:getCurrentUrl()
          }
        },
      });
    },

    onError: () => openDialogAlert('Tenemos problemas con google'),
  });

  return (
    <>
      <StyledButton
        type="button"
        className=""
        variant="outlined"
        fullWidth
        onClick={() => {
          googleLogin();
        }}
        id="google-event-analytics"
        style={{ width: '100%', textTransform: 'none', fontWeight: 700 }}>
        <StyledImage src="/google-logo.png" width={20} height={20} alt="logo" />
        <Typography>Continúa con Google</Typography>
      </StyledButton>
    </>
  );
};
