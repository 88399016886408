import React, { useEffect, useState } from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { CardSection } from '../../shared/components/card/CardSection';
import { TableDefault } from '../../shared/components/table/TableDefault';
import {
  PaymentMethod,
  Subscription,
  useCancelSubscriptionMutation,
  useGetSubscriptionByUserLazyQuery,
} from '../../shared/types/generated';
import { TableColumnType } from '../../shared/components/table/table-types';
import moment from 'moment/moment';
import {
  getStatusTextColor,
  getSubscriptionStatusText,
} from './subscription-utils';
import { MenuActions } from '../../shared/components/menu/MenuActions';
import { DialogDefault } from '../../shared/components/dialog/DialogDefault';
import { openDialogAlert } from '../alert/alert-actions';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    divider: {
      borderWidth: 2,
      borderColor: 'black!important',
      marginTop: '15px!important',
    },
    title: {
      color: '#9CA5AC',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    grey: {
      color: '#9CA5AC',
    },
    line: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid #9CA5AC',
      padding: '15px 0',
    },
  }),
);
export const Subscriptions: React.FC = () => {
  //const { refetch } = useAuth();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 1,
  });
  const [selectedSubscription, setSelectedSubscription] = useState<
    Subscription | undefined
  >(undefined);
  const [total, setTotal] = useState(0);
  const [subscriptions, setSubscriptions] = useState<
    Subscription[] | null | undefined>(undefined);
  const [callSubscriptionsByUser, { loading: subLoading, refetch }] =
    useGetSubscriptionByUserLazyQuery({
      onCompleted: (data) => {
        setSubscriptions(
          data.getSubscriptionsByUserId?.subscriptions as Subscription[],
        );
        setTotal(data.getSubscriptionsByUserId?.total ?? 0);
        console.log({ data });
      },
    });
  const [callAction, { loading }] = useCancelSubscriptionMutation({
    onCompleted: (data) => {
      setOpen(false);
      openDialogAlert(`Se ha cancelado la renovación de tu suscripción. Tu suscripcion termina ${data.cancelSubscription?.periodEndDate}`);
      refetch();
    },
  });
  


  const handleCancelSubscription = (item: Subscription): void => {
    setOpen(true);
    setSelectedSubscription(item);
  };

  const handleClose = (): void => {
    setOpen(false);
    setSelectedSubscription(undefined);
  };

  const COLUMNS: TableColumnType<Subscription>[] = [
    {
      columnName: 'Fecha de Inicio',
      columnValue: (item: Subscription) =>
        moment(parseInt(item?.period_start_date as string)).format(
          'DD-MM-YYYY',
        ),
    },
    {
      columnName: 'Fecha de Renovación',
      columnValue: (item: Subscription) =>
        moment(parseInt(item?.period_end_date as string)).format('DD-MM-YYYY'),
    },
    {
      columnName: 'Recurso',
      columnValue: (item: Subscription) => item?.plan?.title as string,
    },
    {
      columnName: 'Estatus',
      columnValue: null,
      columnComponent: (item) => (
        <Typography color={getStatusTextColor(item?.status)}>
          {getSubscriptionStatusText(item?.status)}
        </Typography>
      ),
    },
    {
      columnName: '',
      columnValue: null,
      columnComponent: (item) => {
        if (item.is_cancel) return null;

        const text = [PaymentMethod.Openpay, PaymentMethod.Paypal].includes(
          item?.payment_method as PaymentMethod,
        )
          ? 'Cancelar renovación'
          : 'Cancelar';

        return (
          <Box className={classes.center}>
            <MenuActions
              item={item}
              actions={[
                {
                  actionName: () => text,
                  onClick: () => handleCancelSubscription(item),
                },
              ]}
            />
          </Box>
        );
      },
    },
  ];
  useEffect(() => {
    callSubscriptionsByUser({
      variables: {
        limit: pagination.pageSize,
        offset: 0,
      },
    });
  }, [callSubscriptionsByUser, pagination.pageSize]);

  return (
    <CardSection
      title={
        <Typography variant="h6" fontWeight={800}>
          Suscripciones
        </Typography>
      }
      subheader={
        <Typography variant="subtitle1">
          Acá encontrarás información detallada de las suscripciones.
        </Typography>
      }
    >
      {subscriptions ? <TableDefault
        count={total}
        items={subscriptions as Subscription[]}
        columns={COLUMNS}
        page={pagination.page}
        pageSize={pagination.pageSize}
        loading={subLoading}
        messageLoading={'Cargando subscripciones...'}
        showPagination
        handleChangePage={(page) => {
          console.log({ page });
          callSubscriptionsByUser({
            variables: {
              offset: page * pagination.pageSize,
              limit: pagination.pageSize,
            },
          });
          setPagination({
            ...pagination,
            page: page + 1,
          });
        }}
      />:null }
      <DialogDefault
        isOpen={open}
        handleClose={() => handleClose()}
        isLoading={loading}
        handleSuccess={() =>
          callAction({
            variables: {
              data: {
                subscription_id: selectedSubscription?.id,
              },
            },
          })
        }
        showActions
        title="Cancelar suscripción"
      >
        <div style={{ fontSize: 18, padding: '20px 0' }}>
          ¿Estas seguro que deseas cancelar tu suscripción?
        </div>
      </DialogDefault>
    </CardSection>
  );
};
