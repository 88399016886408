import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box, TablePagination } from '@mui/material';
import { SavedNoteSingle } from './components/SavedNotesSingle';
import { useUserSavedPostsLazyQuery } from '../../shared/types/generated';
import { useAuth } from '../auth/auth-hook';
import { CardSection } from '../../shared/components/card/CardSection';
import { MainLoader } from '../../shared/components/MainLoader';

type ActiveNum = {
  num?: number;
};
export type PostCustom = {
  url: string;
  title: string;
  minReading: number;
  date: number;
  databaseId: number;
};
export const SavedNotes: React.FC<ActiveNum> = () => {
  const { user } = useAuth();
  const [count, setCount] = useState(0);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 1,
  });

  const [callAction, { data, loading, refetch }] = useUserSavedPostsLazyQuery({
    onCompleted: (res) => {
      setCount(res?.userSavedPosts?.count || 0);
    },
  });

  useEffect(() => {
    callAction({
      variables: {
        where: {
          user: {
            email: {
              equals: user?.email,
            },
          },
        },
        skip: pagination.pageSize * (pagination.page - 1),
        take: pagination.pageSize,
      },
    });
  }, [user?.id, pagination.page, pagination.pageSize, callAction, user?.email]);

  const posts = data?.userSavedPosts?.items || [];

  return (
    <Grid container spacing={4} mb={4}>
      <Grid item xs={12}>
        <CardSection
          title={
            <Typography variant="h6" fontWeight={800}>
              Notas guardadas
            </Typography>
          }
          subheader={
            <Typography variant="subtitle1">
              Acá encontrarás información detallada de las notas guardadas.
            </Typography>
          }>
          <Box pt={2} pr={2} pl={2}>
            <Grid container xs={12}>
              {loading ? (
                <Grid item xs={12}>
                  <MainLoader height="300px">Cargando notas...</MainLoader>
                </Grid>
              ) : (
                posts.map((item) => (
                  <Grid item xs={12} mb={2} key={item?.id}>
                    {user?.id ? (
                      <SavedNoteSingle
                        userId={user?.id}
                        userSavedPost={item}
                        onSuccess={() => refetch()}
                      />
                    ) : null}
                  </Grid>
                ))
              )}
              <Grid>
                <TablePagination
                  count={count}
                  component="div"
                  page={pagination.page - 1}
                  rowsPerPage={pagination.pageSize}
                  onPageChange={(event: unknown, newPage: number) => {
                    setPagination({
                      ...pagination,
                      page: newPage + 1,
                    });
                  }}
                  rowsPerPageOptions={[]}
                />
              </Grid>
            </Grid>
          </Box>
        </CardSection>
      </Grid>
    </Grid>
  );
};
