import React from 'react';
import { Routes as RoutesComponent, Route } from 'react-router-dom';
import { Auth } from './modules/auth/Auth';
import { AuthFacebookCallback } from './modules/auth/AuthFacebookCallback';
import { Session } from './modules/session/Session';
import { Layout } from './shared/components/layout/user/Layout';
import { AuthLogout } from './modules/auth/AuthLogout';
//import { Subscriptions } from './modules/subscription/Subscriptions';
import { SettingAccountView } from './modules/settings/SettingAccountView';
import { LayoutAuth } from './shared/components/layout/auth/LayoutAuth';
import { useAuth } from './modules/auth/auth-hook';
import { MainLoader } from './shared/components/MainLoader';
import { CouponsView } from './modules/coupons/CouponsView';
import { SavedNotes } from './modules/savedNotes/SavedNotes';
import { Invoices } from './modules/invoice/Invoices';
import { Notifications } from './modules/notification/Notifications';
import { Newsletters } from './modules/newsletter/Newsletters';
import { HistoryEntries } from './modules/history/historyEntries';
import { RoleAuthorization } from './modules/rbac/RoleAuthorization';
import { RBACPermissions } from './modules/rbac/rbac-types';
import { ErrorView } from './modules/rbac/ErrorView';
import { PlasnsNewPage } from './modules/plan/PlansNewPage';
import { PaymentMethod } from './modules/payment/PaymentMethod';
import { AlreadySubscribed } from './modules/plan/components/AlreadySubscribed';
//import { LayoutAuthNew } from './shared/components/layout/auth/LayoutAuthNew';
import { Subscriptions } from './modules/subscription/Subscriptions';


export const Routes: React.FC = () => {
  const { isLoading } = useAuth();
  return (
    isLoading ? (
      <MainLoader />
    ) :
        <RoutesComponent>
          <Route
            path="/autenticacion"
            element={
                <Auth />
            }
          />
          <Route path="/logout" element={<AuthLogout />} />
          <Route
            path="/callback/facebook"
            element={<AuthFacebookCallback />}
          />
          <Route
            path="/planes"
            element={
              <RoleAuthorization
                render={() => (
                  <LayoutAuth isInvited={true}>
                    <PlasnsNewPage />
                  </LayoutAuth>
                )}
                permission={RBACPermissions.CAN_SUBSCRIPTION}
                error={() => <AlreadySubscribed />}
              />
            }
          />
          <Route
            path="*"
            element={
              <Session>
                <RoutesComponent>
                  <Route
                    path="/"
                    element={
                      <Layout>
                        <SettingAccountView />
                      </Layout>
                    }
                  />
                  <Route
                      path="/mis-notas-guardadas"
                      element={
                        <Layout>
                          <SavedNotes />
                        </Layout>
                      }
                    />
                  <Route
                    path="/facturas"
                    element={
                      <Layout>
                        <Invoices />
                      </Layout>
                    }
                  />
                  <Route
                    path="/suscripciones"
                    element={
                      <Layout>
                        <Subscriptions />
                      </Layout>
                    }
                  />
                  <Route
                    path="/notificaciones"
                    element={
                      <Layout>
                        <Notifications />
                      </Layout>
                    }
                  />
                  <Route
                    path="/newsletters"
                    element={
                      <Layout>
                        <Newsletters />
                      </Layout>
                    }
                  />
                  
                  <Route
                    path="/historial"
                    element={
                      <Layout>
                        <HistoryEntries/>
                      </Layout>
                    }
                  />
                 <Route
                  path="/metodos-de-pago/:id"
                  element={
                    <RoleAuthorization
                      render={() => (
                        <Layout showSidebar={false} showNotification={false}>
                          <PaymentMethod />
                        </Layout>
                      )}
                      permission={RBACPermissions.CAN_SUBSCRIPTION}
                      error={() => (
                        <ErrorView text="Ya tienes una suscripción activa" />
                      )}
                    />
                  }
                />
                <Route
                  path="/detalles-de-facturacion"
                  element={
                    <Layout>
                      <Invoices />
                    </Layout>
                  }
                />
                <Route
                  path="/cupones"
                  element={
                    <Layout>
                      <CouponsView />
                    </Layout>
                  }
                />
                  <Route path="/logout" element={<AuthLogout />} />

                </RoutesComponent>
              </Session>
            }
          />
        </RoutesComponent>
  );
};
