import React from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { authStore } from '../auth-events';
import { useStore } from '@cobuildlab/react-simple-state';
import { getFormType, getRedirectUrl } from '../auth-utils';
import { authStepAction, authStoreAction } from '../auth-actions';
import { AuthStep } from '../auth-types';
import { useForgotPasswordMutation } from '../../../shared/types/generated';
import { openDialogAlert } from '../../alert/alert-actions';
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../../shared/css/theme';
import { EmailOutlined } from '@mui/icons-material';
import validator from 'validator';
import { BackButton } from '../../../shared/components/buttons/BackButton';

const StyledIcon = styled(EmailOutlined)({
  color: '#999999',
  fontSize: '20px!important',
  paddingRight: '5px',
});

const StyledTypographyText = styled(Typography)({
  color: '#999999',
  fontSize: 16,
});

const StyledButton = styled(ButtonDefault)({
  borderRadius: '4px!important',
  backgroundColor: PRIMARY_COLOR,
  border: 'none!important',
  color: 'white!important',
  height: '38px !important',
  '&:disabled': {
    backgroundColor: SECONDARY_COLOR+'!important',
  },
  textTransform: 'none',
  width: 200,
});


export const AuthForgotPassword: React.FC = () => {
  const form = getFormType() as string;
  const redirectUrl = getRedirectUrl();
  const { email, isLogin } = useStore(authStore);

  const [userForgotPassword, { loading }] = useForgotPasswordMutation({
    onCompleted: () => {
      authStepAction(AuthStep.authLogin);
    },
    onError: (data) => openDialogAlert(data.message),
  });

  return (
    <Box>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Box mb={2}>
            <Typography fontSize={'20px'}>Restaurar contraseña</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} mb={2} style={{ textAlign: 'center' }}>
          <TextField
            variant="outlined"
            value={email}
            name="email"
            placeholder={'Dirección de correo electrónico'}
            type="text"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              startAdornment: <StyledIcon />,
              style: { fontSize: '18px', background: 'white', height: '38px' },
            }}
            onChange={(event) => authStoreAction(event.target.value, isLogin)}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <StyledTypographyText>
            {`Enviaremos un mensaje al correo ${email} para restablecer
              la contraseña`}
          </StyledTypographyText>
        </Grid>
        <Grid item xs={12}>
          <Box textAlign="center" my={2}>
            <StyledButton
              isLoading={Boolean(loading)}
              variant="contained"
              size="large"
              color="primary"
              disabled={!validator.isEmail(email as string)}
              fullWidth
              onClick={() => {
                userForgotPassword({
                  variables: {
                    email,
                    form,
                    redirectUrl,
                  },
                });
              }}
            >
              Aceptar
            </StyledButton>
          </Box>
          <Box display="flex" justifyContent="center">
            <BackButton
              text="volver"
              onClick={() => authStepAction(AuthStep.authLogin)}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};