import React, { useEffect, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import { Box, Theme } from '@mui/material';
import 'react-alice-carousel/lib/alice-carousel.css';
import { createStyles, makeStyles } from '@mui/styles';

type SliderProps = {
  items?: React.ReactNode[];
  infinite?: boolean;
  controls?: boolean;
  onlyOne?: boolean;
};

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    cleanButton: {
      border: 'none!important',
      cursor: 'pointer',
      outline: 'none!important',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);
export const SliderFeaturesPlan: React.FC<SliderProps> = ({
  items,
  controls=true,
  infinite,
  onlyOne = false,
}) => {
  const [responsive, setResponsive] = useState({});
  const [renderKey, setKey] = useState(0);

  useEffect(() => {
    setKey(Date.now());
  }, []);

  const classes = useStyles();
  useEffect(() => {
    setResponsive({
      0: {
        items: 1,
        itemsFit: 'contain',
      },
      520: {
        items: 2,
        itemsFit: 'contain',
      },
      720: {
        items: 3,
        itemsFit: 'contain',
      },
      1240: {
        items: 4,
        itemsFit: 'contain',
      },
    });
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          position: 'relative',
          objectFit: 'contain',
        }}>
        <AliceCarousel
          renderKey={renderKey}
          autoHeight={onlyOne}
          autoWidth={onlyOne}
          mouseTracking
          items={items}
          responsive={responsive}
          infinite={infinite}
          disableButtonsControls={!controls}
          renderDotsItem={({ isActive, activeIndex }) => {
            return (
              <Box
                key={activeIndex}
                style={{
                  width: '12px',
                  height: '11px',
                  borderRadius: '50%',
                  backgroundColor: isActive ? '#000000' : '#FFFFFF',
                  border: '1px solid #000000',
                  margin: '0 5px',
                  cursor: 'pointer',
                }}
              />
            );
          }}
          renderPrevButton={(disabled) => {
            if (disabled.isDisabled) {
              return null;
            }
            return (
              <Box position={'absolute'} bottom={30} left={0}>
                <Box className={classes.cleanButton}>
                  <div style={{ display: 'flex', alignSelf: 'center' }}>
                    <img
                      src="./left-chevron.png"
                      alt="logo"
                      width={42}
                      height={42}
                    />
                  </div>
                </Box>
              </Box>
            );
          }}
          renderNextButton={(disabled) => {
            if (disabled.isDisabled) {
              return null;
            }
            return (
              <Box position={'absolute'} bottom={30} right={0}>
                <Box className={classes.cleanButton}>
                  <div style={{ display: 'flex', alignSelf: 'center' }}>
                    <img
                      src="./right-chevron.png"
                      alt="logo"
                      width={42}
                      height={42}
                    />
                  </div>
                </Box>
              </Box>
            );
          }}
        />
      </div>
    </div>
  );
};
