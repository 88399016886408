import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { PlansSectionOne } from './components/PlansSectionOne';
import { PlansSectionTwo } from './components/PlansSectionTwo';
import { PlansSectionThree } from './components/PlansSectionThree';
import { PlansSectionFour } from './components/PlansSectionFour';
import { DropdownDescription } from './components/DropdownDescription';
import { PlansSectionFive } from './components/PlansSectionFive';
import { PlansSectionSix } from './components/PlansSectionSix';
import {
  //PaypalStatuses,
  Plan,
  PlanStatuses,
  usePlansQuery,
} from '../../shared/types/generated';
import { useCallAction } from '@cobuildlab/react-simple-state';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkoutPlanSubscription } from './plan-actions';
import { useAuth } from '../auth/auth-hook';
import { Loader } from '../../shared/components/Loader';
import { formatNumber } from '../../shared/utils/number-utils';
import { DirectorLetter } from './components/DirectorLetter';
import { FeaturedColumnists } from './components/FeaturedColumnists';
import { useBreakPoint } from '../../shared/hooks/useBreakPoint';
import { PlanSectionTitle } from '../../shared/components/Text';

export const PlasnsNewPage: React.FC = () => {
  const { isBelow }= useBreakPoint('md');
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const { data, loading } = usePlansQuery({
    variables: {
      where: {
        status: PlanStatuses.Active,
        freeTrial: false,
      },
    },
  });

  const [callCheckoutSubscription, loadingCheckout] = useCallAction(
    checkoutPlanSubscription,
    {
      onError: (error) => {
        console.error(error);
      },
    },
  );

  const handleOnSubmit = (item: Plan): void => {
    if (!user) {
      navigate('/autenticacion', {
        state: {
          returnTo: location.pathname,
        },
      });
      return;
    }
    callCheckoutSubscription({
      email: user?.email ?? '',
      priceId: item?.stripe?.price_id ?? '',
      stateUrl: location.pathname,
    });
  };
  return (
    <Box>
      <Container>
        <Box>
          <Box marginTop={2}>
            <PlansSectionOne />
          </Box>
          <Box marginTop={2}>
            <PlansSectionTwo />
          </Box>
          <Box marginTop={2}>
            <PlansSectionThree />
          </Box>
          <Box marginTop={4}>
            <Box
              sx={{
                position: 'relative',
                border: '1px solid',
                display: 'flex',
                justifyContent: 'center',
                py: '20px',
                background: '#FFFFFF',
                borderRadius: '10px',
              }}>
              <Typography
                maxWidth={'806px'}
                fontSize={isBelow? '18px':'24px'}

                lineHeight={'28px'}
                textAlign={'center'}>
                “La información es el fundamento, pero la profundidad de la
                crítica radica en el discernimiento y el juicio reflexivo”
              </Typography>
              <Box
                position={'absolute'}
                top={'-20px'}
                left={0}
                display={'flex'}
                width={'100%'}
                justifyContent={'center'}>
                <img
                  src="./comilla.svg"
                  alt="icono"
                  width={'38px'}
                  height={'38px'}
                  style={{ objectFit: 'contain', background: '#ffffff' }}
                />
              </Box>
            </Box>
          </Box>
            <DirectorLetter />
          <Box marginTop={6}>
            <PlansSectionFour />
          </Box>
          <Box marginTop={2}>
            <FeaturedColumnists />
          </Box>
          <Box marginTop={2}>
            <PlansSectionFive />
          </Box>
          <Box marginTop={2}>
            <PlansSectionSix />
          </Box>
          <Box marginTop={2} paddingBottom={6}>
            <PlanSectionTitle marginBottom={2}>
              Preguntas frecuentes
            </PlanSectionTitle>
            <Box marginTop={1} />
            <DropdownDescription title="¿Que beneficios me da ser miembro?" />
            <Box marginTop={1} />
            <DropdownDescription title="¿Donde puedo usar los descuentos que me ofrece ser miembro de Vanguardia?" />
            <Box marginTop={1} />
            <DropdownDescription title="Si necesito contactarme con Vanguardia en ¿Dónde puedo hacerlo?" />
          </Box>
        </Box>
      </Container>
      <Box
        position={'fixed'}
        left={0}
        width={'100%'}
        bottom={0}
        style={{ background: '#ffffff' }}
        sx={{
          boxShadow: '0px -16px 32px 0 rgba(0, 0, 0, 0.16)',
        }}>
        <Container maxWidth={'md'}>
          <Box position={'relative'} paddingBottom={1}>
            <Box paddingTop={'35px'}>
              <Typography fontSize={18} fontWeight={700} textAlign={'center'}>
                ELIGE TU PLAN:
              </Typography>
              <Grid container>
                {loading || loadingCheckout ? (
                  <Loader />
                ) : (
                  data?.plans?.items?.slice(0, 2)?.map((item, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      marginTop={1}
                      key={index}
                      padding={'2px 5px'}
                      marginBottom={1}>
                      <Box
                        position={'relative'}
                        border="1px solid #000000"
                        paddingY={1}
                        borderRadius={2}>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleOnSubmit(item as Plan);
                          }}
                          style={{
                            width: '100%',
                            textDecoration: 'none',
                            color: '#000000',
                          }}>
                          <Typography
                            fontWeight={700}
                            textAlign={'center'}
                            lineHeight={'16px'}>
                            {item?.title}
                          </Typography>
                          <Typography textAlign={'center'} lineHeight={'16px'}>
                            {formatNumber(Number(item?.price ?? 0))} MXN
                          </Typography>
                        </a>
                        {item?.description?.trim() !== '' ? (
                          <Box
                            position={'absolute'}
                            top={'-10px'}
                            right={'10px'}
                            width={130}
                            component={'div'}
                            borderRadius={'5px'}
                            style={{ background: '#FF7700' }}>
                            <Typography
                              textAlign={'center'}
                              fontSize={12}
                              paddingX={1}
                              paddingY={'2px'}
                              lineHeight={'12px'}
                              fontWeight={700}
                              color={'#ffffff'}>
                              {item?.description}
                            </Typography>
                          </Box>
                        ) : null}
                      </Box>
                    </Grid>
                  ))
                )}
              </Grid>
              <Typography fontSize={16} textAlign={'center'}>
                Cancela o pausa en cualquier momento*
              </Typography>
            </Box>
            <Box
              display={'flex'}
              justifyContent={'center'}
              position={'absolute'}
              width={'100%'}
              top={'-20px'}
              left={0}>
              <img
                src="./corona-centro-fixed.svg"
                alt="logo corona"
                width={49}
                height={49}
              />
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};
