import { APP_ENV } from './constans';
import { getCurrentUrl, getIsIFrame, getPostUrl, getRedirectUrl } from '../modules/auth/auth-utils';
import { openDialogAlert } from '../modules/alert/alert-actions';

export const redirectToHome = (route: string): void => {
  const isIframe = getIsIFrame();
  const redirectTo = getRedirectUrl();
  const postTo = getPostUrl();

  if (redirectTo || postTo) {
    window.location.href = redirectTo || postTo;
  } else if (isIframe) {
    window.parent.location = getCurrentUrl() as string;
  } else {
    window.location.href =
      APP_ENV !== 'local' ? 'https://vanguardia.com' : route;
  }
};
 export const updateQueryParams:(key: string, value: string) => void = (key:string, value:string) => {
  const params = new URLSearchParams(window.location.search);
  params.set(key, value);
  window.history.pushState({}, '', `${window.location.pathname}?${params}`);
  window.location.reload();
};

export const getReadingTime = (textHtml: string): number => {
  const container = document.createElement('div');
  container.innerHTML = textHtml;

  return Math.round((container.textContent as string).split(' ').length / 250);
};

export function formatCurrencyMXN(value: number): string {
  return new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
  }).format(value);
}

export const copyToClipboard = (text: string, message?: string): void => {
  navigator.clipboard.writeText(text).then(() => {
    openDialogAlert(message ?? 'Copiado!');
  });
};