import React from 'react';
import { Grid, Typography, Theme, Box, CircularProgress } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import { ShareButton } from './ShareButton';
import {
  Post,
  useDeleteUserSavedPostMutation,
  UserSavedPost,
} from '../../../shared/types/generated';
import { openDialogAlert } from '../../alert/alert-actions';
import { generateUrlPost } from '../saved-notes-utils';
import { PRIMARY_COLOR } from '../../../shared/css/theme';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    divider: {
      borderWidth: 2,
      borderColor: 'black!important',
      marginTop: '15px!important',
      marginBottom: '15px!important',
    },
    cleanButton: {
      background: 'none!important',
      border: 'none!important',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'items',
    },
    textGray: {
      fontFamily: 'Inter',
      color: '#9CA5AC',
    },
    textTitle: {
      fontFamily: 'Inter',
      fontWeight: 'bold !important',
      color: 'black !important',
      textDecoration: 'none !important',
      underline: ' !important',
    },
    textwithoutUnderline: {
      textDecoration: 'none !important',
      underline: ' !important',
    },
    textClasses: {
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '15px !important',
      },
    },
  }),
);
type ActiveNum = {
  userSavedPost?: UserSavedPost | null;
  onSuccess: () => void;
  userId:number;
};

export const SavedNoteSingle: React.FC<ActiveNum> = ({ userSavedPost, onSuccess, userId }) => {
  const classes = useStyles();
  const post= userSavedPost?.post??null;
  const [callDeleteAction, { loading }] = useDeleteUserSavedPostMutation({
    variables: {
      where: {
        user:{
          id:userId
        },
        post:{
          id:(userSavedPost?.post?.id)??0,
        },
        id: userSavedPost?.id??0
      },
    },
    onCompleted: () => {
      onSuccess();
    },
    onError: (e) => {
      const message=e.message;
      console.log({message});
      openDialogAlert('Tenemos problemas para eliminar la nota.');
    },
  });

  const readingTime = Math.round((post?.reading_time??0)/60); //getReadingTime('hola' as string);
  const url = generateUrlPost(post as Post);

  return (
    <>
      <Grid
        container
        style={{ boxShadow: '0px 3px 6px #00000029', background: 'white' }}
        xs={12}
      >
        <Grid display={'flex'} paddingRight={2} item xs={12} sm={4}>
          <Box
            width={38}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            minHeight={'150px'}
            component={'div'}
            style={{ background: '#F6F6F6' }}
            marginRight={2}
          >
            <button
              className={classes.cleanButton}
              onClick={() => callDeleteAction()}
            >
              {loading ? (
                <CircularProgress style={{ color:PRIMARY_COLOR, paddingBottom: '10px' }} size="10px" />
              ) : (
                <DeleteIcon
                  style={{ color:PRIMARY_COLOR, paddingBottom: '10px' }}
                />
              )}
            </button>
            <ShareButton url={url} title={post?.title?? ''} />
          </Box>
          <Box
            width={180}
            height={{xs:130,md:100}}
            paddingY={2}
            component={'a'}
            href={url}
            target="_blank"
          >
            <img
              src={(post?.post_image?.source as string)??'/short-logo.png'}
              alt="post"
              width={'100%'}
              height={'100%'}
              style={{ flexGrow: 1, objectFit:'contain' }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} paddingRight={2}>
          <Box
            paddingY={2}
            height={'70%'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-between'}
            className={classes.textClasses}
          >
            <Box
              component={'a'}
              className={classes.textwithoutUnderline}
              href={url}
              target="_blank"
            >
              <Typography className={classes.textTitle}>
                {post?.title}
              </Typography>
            </Box>
            <Box>
              <Grid container xs={12}>
                <Grid
                  item
                  xs={12}
                  md={8}
                  display={'flex'}
                  justifyContent={'space-between'}
                >
                  <Typography className={classes.textGray}>
                    {`${readingTime} ${
                      readingTime === 1 ? 'minuto' : 'minutos'
                    } de lectura`}
                  </Typography>
                  <Typography className={classes.textGray}>
                    {moment(post?.updated_at).format('DD [de] MMMM, YYYY')}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
